











import Vue from "vue";
import { Action, Getter } from "vuex-class";
import Component from "vue-class-component";
import { Prop, Ref } from 'vue-property-decorator';
import StoreAction from '@/interfaces/storeAction';
import { TrackJS } from 'trackjs';
import StoreGetter from "@/interfaces/storeGetter";
@Component({})
export default class GoogleSignInButton extends Vue {
    @Getter(StoreGetter.IsLoading) isLoading;
    @Action(StoreAction.Login) login;
    @Action(StoreAction.SetError) setError;
    @Prop(Function) onSignInSuccess: Function;
    @Prop(Array) scopes:string[];
    @Ref('googleButtonContainer') googleButtonContainer
    authInProgress: boolean = false;
    googleScriptShouldBeLoadedByNow = false;

    get isLoadingShown()
    {
        return this.isLoading || this.authInProgress;
    }

    buttonStyle(): object {
        return {
            height: '48px',
            'background-color': 'white'
        }
    }

    created() {
        // Wait one second before displaying the privacy blocker default text
        setTimeout(() => this.googleScriptShouldBeLoadedByNow = true, 1000);
    }

    mounted()
    {
        this.$google.registerButton(this.googleButtonContainer, this.defaultOnSignInSuccess, this.scopes);
    }

    async defaultOnSignInSuccess(user) {
        this.authInProgress = true;
        try
        {
            if (await this.login({ provider: "google", token: user.credential})) {
                this.$emit("confirm", user);
                this.$store.dispatch(StoreAction.ResetError);
            } else {
                this.setError({ message: this.$t("LOGIN_UNABLE_TO_LOGIN_TEXT") });
            }
        }
        catch(e)
        {
            this.setError(e);
            TrackJS.track(e);
        }
        finally 
        {
            this.authInProgress = false;
        }
    }
    beforeDestroy()
    {
        this.authInProgress = false;
    }
}
