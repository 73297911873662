var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _vm.coursesFetched
            ? [
                _vm.courses.length > 0
                  ? [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("LTI_SELECT_STEP_TEXT", {
                              canvasCourseName: _vm.canvasCourseName,
                            })
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("v-select", {
                        attrs: {
                          items: _vm.courses,
                          "item-text": "name",
                          "return-object": true,
                          label: _vm.$t("LTI_SELECT_SELECT_COURSE_LABEL"),
                          loading: _vm.fetchingCourses,
                          disabled: _vm.isLoading || _vm.fetchingCourses,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(data.item.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticClass:
                                            "caption grey--text text--darken-1",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getCourseInstructors(
                                                data.item
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4273428200
                        ),
                        model: {
                          value: _vm.selectedCourse,
                          callback: function ($$v) {
                            _vm.selectedCourse = $$v
                          },
                          expression: "selectedCourse",
                        },
                      }),
                    ]
                  : [
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("LTI_SELECT_NO_COURSES", {
                              canvasCourseName: _vm.canvasCourseName,
                            })
                          ),
                        },
                      }),
                    ],
              ]
            : [
                _c("loading", {
                  attrs: { message: _vm.$t("LTI_SELECT_LOADING") },
                }),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "pl-2 pr-3 pb-3" },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { color: "confirm", text: "", disabled: _vm.isLoading },
              on: { click: _vm.cancel },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("LTI_SELECT_STEP_CANCEL")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "elevation-0",
              attrs: {
                color: "primary",
                disabled:
                  _vm.isLoading ||
                  _vm.fetchingCourses ||
                  !_vm.selectedCourse.id,
              },
              on: { click: _vm.confirm },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("LTI_SELECT_STEP_CONFIRM")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }