enum AddUserMethod
{
    courseCode,
    invitation,
    lti,
    directJoin,
    credential
}

export default AddUserMethod;
