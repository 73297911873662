enum StoreAction
{
    LoadingBegin = "loadingBegin",
    LoadingEnd = "loadingEnd",
    SetError = "setError",
    ResetError = "resetError",
    ResetIdentityProvider = 'resetIdentityProvider',
    Login = "login",
    GetSsoToken = 'getSsoToken',
    TryLogin = 'tryLogin',
    FetchClaims = "fetchClaims",
    ClearClaims = "clearClaims",
    ValidateToken = "validateToken",
    CreateUser = "createUser",
    PaymentRequired = "paymentRequired",
    PurchaseVendible = "purchaseVendible",
    ProcessPaymentConfirmed = "processPaymentConfirmed",
    CustomApiErrorHandler = "customApiErrorHandler",
    RestorePaymentRequiredData = "restorePaymentRequiredData"
}

export default StoreAction;
