import * as msal from '@azure/msal-browser';
import Vue from 'vue';


declare module 'vue/types/vue' {
    interface Vue {
        $msal: msal.PublicClientApplication
    }
}

export default {
    install(vue: typeof Vue, options: { clientId: string, redirectUri: string })
    {
        vue.prototype.$msal = new msal.PublicClientApplication({
            auth: {
                clientId: options.clientId,
                redirectUri: options.redirectUri,
            }
        });
    }
}
