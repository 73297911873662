import { Validator } from 'vee-validate';
import Vue from 'vue';
export async function validateAll(context: Vue): Promise<boolean> {
    const validator:Validator = context['$validator'];
    let result = await validator.validateAll();
    if (!result) {
        validator.errors.items.forEach(item => {
            item.msg = `${context['$t']('ERROR_PREFIX')} ${item.msg}`;
        })
    }
    return result;
}