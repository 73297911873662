import Config from '../../config';
import IUserCreator from "./iUserCreator";
import UserCreator from "./userCreator";

export default class CredentialUserCreator extends UserCreator implements IUserCreator
{
    private credentialId: string;

    constructor(storeContext, credentialId: string)
    {
        super(storeContext);
        this.credentialId = credentialId;
    }

    create()
    {
        return this.userApiClient.createUserByCredential(this.credentialId);
    }

    async afterCreate()
    {
        window.location.href = new URL(`?s=${await this.getSsoToken()}`, Config.CYBER_RANGE_UI_BASE_URL).href;
    }
}
