import Config from '@/config';
import StoreGetter from '@interfaces/storeGetter';
import IStoreState from '@interfaces/iStoreState';
import { Organization } from '@cyber-range/cyber-range-api-organization-client'
import OrganizationStoreCustomAttribute from '@interfaces/organizationStoreCustomAttribute';
import { capitalize } from '@entities/filters/definitions/capitalize';
import BusinessUnitStrings from '@entities/strings/definitions/businessUnitStrings';

export default {
    state:{
        currentOrganizationCustomAttributes: {},
    },
	getters: {
        [StoreGetter.BusinessUnitDisplayCategory]: (state:IStoreState) => (organization?: Organization) => {
            if(organization !== undefined) {
                const orgAttr = organization.customAttributes? organization.customAttributes.find(attr => attr.name === OrganizationStoreCustomAttribute.BusinessCategory): undefined;
                if(orgAttr){
                    return capitalize(orgAttr.value);
                }
            }else{
                if(state.currentOrganizationCustomAttributes && state.currentOrganizationCustomAttributes[OrganizationStoreCustomAttribute.BusinessCategory]){
                    return capitalize(state.currentOrganizationCustomAttributes[OrganizationStoreCustomAttribute.BusinessCategory]);
                }
            }

            return BusinessUnitStrings.en.BUSINESS_CATEGORY;
        },
        [StoreGetter.BusinessUnitDisplayName]: (state:IStoreState) => (organization?: Organization) => {
            if(organization !== undefined){
                const orgAttr = organization.customAttributes? organization.customAttributes.find(attr => attr.name === OrganizationStoreCustomAttribute.BusinessUnit): undefined;
                if(orgAttr){
                    return capitalize(orgAttr.value);
                }
            }else{
                if(state.currentOrganizationCustomAttributes && state.currentOrganizationCustomAttributes[OrganizationStoreCustomAttribute.BusinessUnit]){
                    return capitalize(state.currentOrganizationCustomAttributes[OrganizationStoreCustomAttribute.BusinessUnit]);
                }
            }
            return BusinessUnitStrings.en.BUSINESS_UNIT;
        },
        [StoreGetter.BusinessUnitDisplayRegion]: (state:IStoreState) => (organization?: Organization) => {
            if(organization !== undefined){
                const orgAttr = organization.customAttributes? organization.customAttributes.find(attr => attr.name === OrganizationStoreCustomAttribute.BusinessRegion): undefined;
                if(orgAttr){
                    return capitalize(orgAttr.value);
                }
            }else{
                if(state.currentOrganizationCustomAttributes && state.currentOrganizationCustomAttributes[OrganizationStoreCustomAttribute.BusinessRegion]){
                    return capitalize(state.currentOrganizationCustomAttributes[OrganizationStoreCustomAttribute.BusinessRegion]);
                }
            }
            return BusinessUnitStrings.en.BUSINESS_REGION;
        },
	}
};
