






























































import Vue from 'vue';
import Component from "vue-class-component";
import { convertToHyphenSeparatedAlphaNumeric } from '@/utils/convertToHyphenSeparatedAlphaNumeric';
import { Prop, Ref } from "vue-property-decorator";
import { v4 as uuid } from 'uuid';

const defaultFilter = (_: any, queryText: string, itemText: string) => itemText.toLocaleLowerCase().includes(queryText.toLocaleLowerCase());

@Component
export default class AccessibleCombobox extends Vue {
    @Prop(Array) items: any[];
    @Prop([String,Function]) itemText: string|((item: object|string) => string);
    @Prop([String,Function]) itemValue: string|((item: object|string) => string);
    @Prop({ type: Function, default: defaultFilter }) filter: (item: any, queryText: string, itemText: string) => boolean;
    @Prop(String) label: string;
    @Prop(Boolean) disabled: boolean;
    @Prop(String) value: string;
    @Prop(Boolean) allowNew: boolean;

    @Ref('input') inputRef: Vue;
    @Ref('listitem') listitemRef: HTMLElement[];

    focusedItemIndex: number = -1;
    isMenuShowing: boolean = false;
    id: string = uuid();
    listboxId: string = `${this.id}-listbox`;
    queryText: string = null;
    listStyle = {
        'overflow-y': 'auto',
        'max-height': '40vh',
    }
    nudgeTop = 0;

    testingSelector(itemValue:string)
    {
        return `cr-accessible-combobox-selection-${convertToHyphenSeparatedAlphaNumeric(itemValue)}`;
    }

    get displayText()
    {
        if (this.queryText !== null)
        {
            return this.queryText;
        }
        if (!this.value)
        {
            return '';
        }
        const item = this.findItem(this.value);
        if (!item)
        {
            return this.allowNew ? this.value : '';
        }
        return this.getItemText(item);
    }
    set displayText(text: string)
    {
        this.queryText = text;
        if (this.allowNew)
        {
            this.$emit('input', text);
        }
    }

    get selectedItemId(): string|false
    {
        if (this.focusedItemIndex === -1)
        {
            return false;
        }
        return this.listitemRef[this.focusedItemIndex]?.id;
    }

    get filteredMenuItems(): { text: string, value: string }[]
    {
        const items: { text:string, value: string }[] = [];
        for (const item of this.items)
        {
            const itemText = this.getItemText(item);
            if (this.filter(item, this.queryText || '', itemText))
            {
                items.push({ text: itemText, value: this.getItemValue(item) });
            }
        }
        return items;
    }

    openMenu()
    {
        if (this.isMenuShowing) return;

        this.isMenuShowing = true;
        const size = window.innerHeight - this.inputRef.$el.getBoundingClientRect().bottom;
        this.listStyle['max-height'] = `${size}px`;
        this.nudgeTop = this.inputRef.$el.querySelector('.v-input__control').getBoundingClientRect().height - this.inputRef.$el.querySelector('.v-input__slot').getBoundingClientRect().height;
    }
    closeMenu()
    {
        this.isMenuShowing = false;
        this.focusedItemIndex = -1;
        this.queryText = null;
    }

    async onInputBlur(event: FocusEvent)
    {
        // Delay closing list so that a list items click event can happen before the list disappears
        await new Promise(resolve => setTimeout(resolve, 200));
        this.closeMenu();
    }

    onInputKeydown(event: KeyboardEvent)
    {
        if (event.ctrlKey || event.altKey || event.metaKey) return;

        switch (event.key) {
            case 'Escape':
            {
                if (this.isMenuShowing)
                {
                    event.stopPropagation();
                    this.closeMenu();
                    this.setSelection(this.value);
                }
                break;
            }
            case 'ArrowUp':
            {
                this.openMenu();
                const itemCount = this.listitemRef?.length || 0;
                this.setFocus(this.focusedItemIndex <= 0 ? itemCount - 1 : this.focusedItemIndex - 1);
                event.preventDefault();
                break;
            }
            case 'ArrowDown':
            {
                this.openMenu();
                const itemCount = this.listitemRef?.length || 0;
                this.setFocus((this.focusedItemIndex + 1) % itemCount);
                event.preventDefault();
                break;
            }
            case 'Enter':
            {
                if (this.isMenuShowing)
                {
                    const selectedItem = this.filteredMenuItems[this.focusedItemIndex].value;
                    this.closeMenu();
                    this.setSelection(selectedItem);
                }
                break;
            }
            default:
            {
                this.openMenu();
                break;
            }
        }
    }

    setFocus(index: number, doScrollIntoView: boolean = true)
    {
        if (Number.isNaN(index))
        {
            index = -1;
        }

        this.focusedItemIndex = index;
        if (index === -1) return;

        if (doScrollIntoView)
        {
            this.listitemRef[index]?.scrollIntoView();
        }
    }

    setSelection(value: string): void {
        this.$emit("input", value);
        this.queryText = null;
    }

    findItem(value: string)
    {
        return this.items.find(item => this.getItemValue(item) === value);
    }

    getItemText(item: any): string
    {
        if (typeof this.itemText === 'function')
        {
            return this.itemText(item);
        }
        else if (typeof item === 'string')
        {
            return item;
        }
        else
        {
            return item[this.itemText];
        }

    }

    getItemValue(item: any): string
    {
        if (typeof this.itemValue === 'function')
        {
            return this.itemValue(item);
        }
        else if (typeof item === 'string')
        {
            return item;
        }
        else
        {
            return item[this.itemValue];
        }
    }
}
