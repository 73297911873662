import Config from "@/config";
import StoreAction from "@/interfaces/storeAction";
import StoreGetter from "@/interfaces/storeGetter";
import { IBalanceApiClient } from "@cyber-range/cyber-range-api-balance-client";
import { ActivityType } from "@cyber-range/cyber-range-api-balance-client/dist/interfaces/activityType";
import { IPayment, IPaymentApiClient, IVendible } from "@cyber-range/cyber-range-api-payment-client";
import IPaymentProcessor from "./iPaymentProcessor";

export default class OrganizationBalancePaymentProcessor implements IPaymentProcessor {
    private balanceApiClient: IBalanceApiClient;
    private paymentApiClient: IPaymentApiClient;
    private _payment: IPayment;

    constructor(private store, private vendible: IVendible)
    {
        this.balanceApiClient = store.getters[StoreGetter.BalanceApiClient];
        this.paymentApiClient = store.getters[StoreGetter.PaymentApiClient];
    }


    private async getSsoToken(): Promise<string>
    {
        return await this.store.dispatch(StoreAction.GetSsoToken);
    }

    private async getPaymentId()
    {
        if (!this._payment)
        {
            const response = await this.paymentApiClient.get({
                organizationId: this.vendible.organizationId,
                vendibleId: this.vendible.id,
                limit: 1,
            });
            this._payment = response.items[0];
        }

        return this._payment
    }

    private async checkBalanceActivityExistence(): Promise<boolean>
    {
        const payment = await this.getPaymentId();

        if (!payment)
        {
            return false;
        }

        let fromDate = new Date(payment.createdTimestamp);
        fromDate.setDate(fromDate.getDate() - 1);

        let toDate = new Date(payment.createdTimestamp);
        toDate.setDate(toDate.getDate() + 1);

        //Get Balance Activities
        const balanceActivities = (await this.balanceApiClient.listOrganizationBalanceActivities(this.vendible.organizationId, {
            postedFrom: fromDate.toISOString().substring(0,10),
            postedTo: toDate.toISOString().substring(0,10),
        })).items;

        // Check to see if new balance activity was created
        return balanceActivities.some(activity => activity.type === ActivityType.Payment &&  activity.referenceId === payment.id);
    }

    async process()
    {
        let balanceActivityExists = false

        do {
            const delayPromise = new Promise(res => setTimeout(res, 2000));

            balanceActivityExists = await this.checkBalanceActivityExistence();

            if(!balanceActivityExists)
            {
                await delayPromise;
            }

        } while (!balanceActivityExists)

        window.location.assign(new URL(`/organizations/${this.vendible.organizationId}/billing?s=${await this.getSsoToken()}`, Config.CYBER_RANGE_UI_BASE_URL).href);
    }
}
