var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isErrorState && !_vm.retryableError
        ? _c(
            "v-alert",
            {
              staticClass: "mb-4",
              attrs: {
                role: "alert",
                type: "error",
                value: _vm.error,
                icon: "error",
                dense: "",
              },
            },
            [_vm._v(_vm._s(_vm.error))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.creatingUser
        ? [
            _c("loading", {
              attrs: { message: _vm.$t("REGISTRATION_LOADING_MESSAGE") },
            }),
          ]
        : _c(
            "div",
            { staticClass: "elevation-0" },
            [
              _c("h1", [
                _vm._v(_vm._s(_vm.$t("REGISTRATION_COURSE_REGISTRATION"))),
              ]),
              _vm._v(" "),
              _vm.error && _vm.retryableError
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        roler: "alert",
                        type: "error",
                        icon: "error",
                        value: !!_vm.error,
                        dense: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.error))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("announcement"),
              _vm._v(" "),
              _vm.course.courseName
                ? [
                    _c("p", {
                      staticClass: "mt-3 mb-3",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("REGISTRATION_CONFIRM_MESSAGE", {
                            course: _vm.course.courseName,
                          })
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _vm.isValidCourseCode || true
                      ? _c("login-buttons", {
                          attrs: {
                            providers: ["google", "azure"],
                            mode: "registration",
                            transition: "fade-transition",
                          },
                          on: { confirm: _vm.onLoginSuccess },
                        })
                      : _vm._e(),
                  ]
                : [
                    _c("p", { staticClass: "mt-3 mb-3" }, [
                      _vm._v(_vm._s(_vm.$t("REGISTRATION_INSTRUCTIONS"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: _vm.$t("INVITATION_CODE_LABEL"),
                            required: true,
                            disabled: _vm.loading,
                          },
                          on: { input: _vm.onCourseCodeChange },
                          model: {
                            value: _vm.courseCode,
                            callback: function ($$v) {
                              _vm.courseCode = $$v
                            },
                            expression: "courseCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.loading
                      ? _c(
                          "div",
                          { staticClass: "text-xs-center" },
                          [_c("loading", { attrs: { message: "Validating" } })],
                          1
                        )
                      : _vm._e(),
                  ],
              _vm._v(" "),
              _c("router-link", { attrs: { to: { name: "login" } } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("REGISTRATION_ALREADY_REGISTERED")) +
                    "\n      "
                ),
              ]),
            ],
            2
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }