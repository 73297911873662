import StoreAction from '../../interfaces/storeAction';
import StoreGetter from '../../interfaces/storeGetter';
import { IUserApiClient } from '@cyber-range/cyber-range-api-user-client';
import { ActionContext } from 'vuex';
import IStoreState from '@/interfaces/iStoreState';

export default abstract class UserCreator
{
    constructor(protected storeContext: ActionContext<IStoreState, IStoreState>) {}

    async getSsoToken(): Promise<string>
    {
        return await this.storeContext.dispatch(StoreAction.GetSsoToken);
    }

    get userApiClient(): IUserApiClient
    {
        return this.storeContext.getters[StoreGetter.BackgroundUserApiClient];
    }
}
