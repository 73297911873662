var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-alert",
        {
          staticClass: "mb-4",
          attrs: { type: "info", icon: "info", dense: "", value: !!_vm.info },
        },
        [_vm._v(_vm._s(_vm.info))]
      ),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticClass: "mb-4",
          attrs: {
            type: "warning",
            icon: "warning",
            dense: "",
            value: !!_vm.warning,
          },
        },
        [_vm._v(_vm._s(_vm.warning))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }