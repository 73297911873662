var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-progress-circular", {
        staticClass: "progress",
        attrs: { indeterminate: "", size: "20", width: 3, color: "primary" },
      }),
      _vm._v(" "),
      _vm.message !== false
        ? [_vm._v(_vm._s(_vm.message || _vm.$t("LOADING_MESSAGE")))]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }