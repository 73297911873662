


















import Vue from "vue";
import StoreAction from "@interfaces/storeAction";
import LoginButtons from "./LoginButtons.vue";
import Component from "vue-class-component";
import StoreGetter from "@interfaces/storeGetter";
import { Getter, Action } from "vuex-class";
import { Route } from 'vue-router';
import Config from '../../config';
import { Prop, Watch } from 'vue-property-decorator';
import Announcement from '@utils/Announcement.vue';
import { resolve } from 'url';
import { IClaim } from "@cyber-range/cyber-range-api-entitlement-client";
import { CreateUserParams } from '@/utils/userCreation/createUserParams';
import AddUserMethod from '@/interfaces/addUserMethod';
import {Credential} from "@cyber-range/cyber-range-api-credential-client"

@Component({
    components: { LoginButtons, Announcement }
})
export default class Login extends Vue {
    @Getter(StoreGetter.GetErrorMessage) error;
    @Getter(StoreGetter.GetClaims) getClaims: (type: string) => IClaim[];
    @Getter(StoreGetter.GetDisplayName) getDisplayName;
    @Action(StoreAction.SetError) setError;
    @Action(StoreAction.ResetError) resetError;
    @Action(StoreAction.GetSsoToken) getSsoToken: () => Promise<string>;
    @Getter(StoreGetter.CanViewExerciseArea) canViewExerciseArea;
    @Action(StoreAction.FetchClaims) fetchClaims;
    @Action(StoreAction.LoadingBegin) loadingBegin: () => void;
    @Action(StoreAction.LoadingEnd) loadingEnd: () => void;
    
    get credentialCode() {
        return this.$route.query ? this.$route.query.code : undefined;
    }

    async confirm()
    {
        if (this.credentialCode)
        {
            this.loadingBegin();
            await this.$store.dispatch(StoreAction.CreateUser, new CreateUserParams(AddUserMethod.credential, Credential.idFromDisplayName(this.getDisplayName)));
        }

        // Disable all buttons while the user is being redirected to the destination.
        this.loadingBegin();

        await this.fetchClaims();

        let ssoToken:string;
        
        try
        {
            ssoToken = await this.getSsoToken();
        }
        catch(e)
        {
            this.setError({message:this.$t('LOGIN_ACCOUNT_NOT_FOUND')});
            this.loadingEnd();
            return;
        }

        if(this.$route.query.referrer_url && this.getClaims('role').some(claim => claim.value === 'coursewareadmin'))
        {
            await this.redirectToUrl(<string> this.$route.query.referrer_url, ssoToken);
        }
        else if(this.$route.query.redirect_url)
        {
            await this.redirectToUrl(<string> this.$route.query.redirect_url, ssoToken);
        }
        else if(this.canViewExerciseArea)
        { 
            window.location.href = Config.CYBER_RANGE_UI_BASE_URL + `?s=${ssoToken}`;
        }
        else
        {
            window.location.href = Config.HOMEPAGE_UI_BASE_URL + `?s=${ssoToken}`;
        }
    }

    async redirectToUrl(url:string, ssoToken:string): Promise<boolean>
    {
        if (url.startsWith('/'))
        {
            this.$router.push(url);
            return true;
        }

        if(!Config.REDIRECT_URLS.split(',').some(u => u === url || new RegExp(u).test(url) === true))
        {
            this.setError({ message: this.$t("INVALID_REDIRECT_URL", { url }) });
            return false;
        }

        if(!url.includes('?'))
        {
            url += '?';
        }    

        window.location.href = `${url}&s=${ssoToken}`;
        return true;
    }

    get loginSupportUrl() {
        return Config.LOGIN_SUPPORT_URL ? Config.LOGIN_SUPPORT_URL : resolve(Config.SUPPORT_URL, '/troubleshooting/cant-login.html');
    }

    get anonymousUsersArticleUrl() {
        return Config.LOGIN_SUPPORT_URL ? Config.LOGIN_SUPPORT_URL : resolve(Config.SUPPORT_URL, '/faq/us-what-is-anonymous-password.html');
    }

    get featureSignInAsAnonymousUserEnabled():boolean
    {
        return !!Config.FEATURE_SIGN_IN_AS_ASNONYMOUS_USER_ENABLED;
    }
}
