var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "", "elevation-0": "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "align-center": "",
                "justify-center": "",
                "mb-5": "",
                "pb-5": "",
              },
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", md6: "" } },
                [_c("router-view", { key: _vm.$route.fullPath })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }