




import Vue from 'vue';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';

@Component({})
export default class Main extends Vue {
    
    async created(){
        
    }
}
