var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-6 mb-1", staticStyle: { "max-width": "320px" } },
    [
      _vm.text
        ? _c("v-flex", { attrs: { xs12: "", "mb-4": "" } }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.text) } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (_vm.showBasicLogin && !_vm.identityProvider) ||
                _vm.identityProvider === "basic",
              expression:
                "showBasicLogin && !identityProvider || identityProvider==='basic'",
            },
          ],
          attrs: { xs12: "" },
        },
        [
          _c(
            "v-expansion-panels",
            { staticClass: "elevation-0" },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "mb-1 elevation-0" },
                [
                  _c(
                    "v-expansion-panel-header",
                    { staticClass: "loginExpensionHeader" },
                    [_vm._v(_vm._s(_vm.$t("LOGIN_BASIC_LOGIN")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-expansion-panel-content",
                    [
                      _c("basic-auth", {
                        staticClass: "pa-3",
                        on: { confirm: _vm.confirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.providers.includes("google") &&
                _vm.googleLoginEnabled &&
                (!_vm.identityProvider || _vm.identityProvider === "google"),
              expression:
                "providers.includes('google') && googleLoginEnabled && (!identityProvider || identityProvider==='google')",
            },
          ],
          attrs: { xs12: "", "mb-4": "" },
        },
        [
          _c("google-sign-in-button", {
            attrs: { scopes: ["profile", "email", "openid"] },
            on: { confirm: _vm.confirm },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.providers.includes("azure") &&
                _vm.azureAdLoginEnabled &&
                (!_vm.identityProvider || _vm.identityProvider === "azuread"),
              expression:
                "providers.includes('azure') && azureAdLoginEnabled && (!identityProvider || identityProvider==='azuread')",
            },
          ],
          attrs: { xs12: "", "mb-4": "" },
        },
        [_c("msal-login-button", { on: { confirm: _vm.confirm } })],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.identityProvider,
              expression: "identityProvider",
            },
          ],
          attrs: { xs12: "", "mb-4": "" },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "elevation-0",
              staticStyle: { "background-color": "white" },
              attrs: { block: "" },
              on: { click: _vm.showAllLoginOptions },
            },
            [_vm._v("Other Login Options")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.providers.includes("credential_user") &&
                _vm.credentialUserLoginEnabled &&
                (!_vm.identityProvider ||
                  _vm.identityProvider === "credential_user"),
              expression:
                "providers.includes('credential_user') && (credentialUserLoginEnabled && (!identityProvider || identityProvider==='credential_user'))",
            },
          ],
          staticStyle: { "max-width": "320px" },
          attrs: { xs12: "", "mb-4": "" },
        },
        [
          _c("credential-user-login-button", {
            attrs: {
              "credential-code": _vm.credentialCode,
              mode: _vm.mode,
              competitionId: _vm.competitionId,
            },
            on: { confirm: _vm.confirm },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                _vm.providers.includes("credential_anonymous") &&
                _vm.featureSignInAsAnonymousUserEnabled &&
                (!_vm.identityProvider ||
                  _vm.identityProvider === "credential_anonymous"),
              expression:
                "providers.includes('credential_anonymous') && featureSignInAsAnonymousUserEnabled && (!identityProvider || identityProvider==='credential_anonymous')",
            },
          ],
          staticStyle: { "max-width": "320px" },
          attrs: { xs12: "", "mb-4": "" },
        },
        [
          _c("credential-anonymous-login-button", {
            attrs: {
              "credential-code": _vm.credentialCode,
              mode: _vm.mode,
              competitionId: _vm.competitionId,
            },
            on: { confirm: _vm.confirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }