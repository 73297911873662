import NoPaymentSessionDataFoundError from "@/entities/errors/noPaymentSessionDataFoundError";
import IPaymentRequiredData from "@/interfaces/iPaymentRequiredData";
import PaymentRequiredAction from "@/interfaces/paymentRequiredAction";
import StoreAction from "@/interfaces/storeAction";
import StoreGetter from "@/interfaces/storeGetter";
import { IVendible, IVendibleApiClient, VendibleType } from "@cyber-range/cyber-range-api-payment-client";
import { Store } from "vuex";
import IPaymentProcessor from "./iPaymentProcessor";
import OrganizationBalancePaymentProcessor from "./organizationBalancePaymentProcessor";
import UserPaymentProcessor from "./userPaymentProcessor";

export default class PaymentProcessorFactory {
    static async create(store: Store<any>, vendibleId: string): Promise<IPaymentProcessor> {
        const data: IPaymentRequiredData = store.getters[StoreGetter.GetVendiblePaymentRequiredError](vendibleId);

        if (!data)
        {
            const vendibleApiClient: IVendibleApiClient = store.getters[StoreGetter.VendibleApiClient];
            let vendible: IVendible;
            try
            {
                vendible = await vendibleApiClient.getOne(vendibleId);
            } catch (error) {}

            if (vendible?.type === VendibleType.OrganizationBalance)
            {
                return new OrganizationBalancePaymentProcessor(store, vendible);
            }
        }
        else if (data.source.action === PaymentRequiredAction.UserCreation)
        {
            return new UserPaymentProcessor(store, data);
        }

        throw new NoPaymentSessionDataFoundError();
    }
}
