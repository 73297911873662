import AddUserMethod from '@/interfaces/addUserMethod';
import IStoreState from '@/interfaces/iStoreState';
import { ActionContext } from 'vuex';
import CourseCodeUserCreator from './CourseCodeUserCreator';
import { CreateUserArgs } from './createUserArgs';
import DirectJoinUserCreator from './directJoinUserCreator';
import InvitationUserCreator from './InvitationUserCreator';
import IUserCreator from './iUserCreator';
import LtiUserCreator from './ltiUserCreator';
import CredentialUserCreator from './credentialUserCreator';

export class UserCreatorFactory
{
    static create<T extends AddUserMethod>(storeContext: ActionContext<IStoreState, IStoreState>, method: T, args: CreateUserArgs<T>): IUserCreator
    {
        switch (method) {
            case AddUserMethod.invitation:
                return new InvitationUserCreator(storeContext, args as CreateUserArgs<AddUserMethod.invitation>);
            case AddUserMethod.courseCode:
                return new CourseCodeUserCreator(storeContext, args as CreateUserArgs<AddUserMethod.courseCode>);
            case AddUserMethod.lti:
                return new LtiUserCreator(storeContext, args as CreateUserArgs<AddUserMethod.lti>)
            case AddUserMethod.directJoin:
                return new DirectJoinUserCreator(storeContext, args as CreateUserArgs<AddUserMethod.directJoin>);
            case AddUserMethod.credential:
                return new CredentialUserCreator(storeContext, args as CreateUserArgs<AddUserMethod.credential>);
            default:
                throw new Error(`No creation process for user creation method '${method}'.`);
        }
    }
}
