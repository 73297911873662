var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          staticClass: "mb-4",
          attrs: {
            role: "alert",
            type: "error",
            icon: "error",
            dense: "",
            value: !!_vm.error,
          },
        },
        [_vm._v(_vm._s(_vm.error))]
      ),
      _vm._v(" "),
      _c("announcement"),
      _vm._v(" "),
      _vm.redirecting
        ? [_c("loading", { attrs: { message: _vm.$t("LTI_REDIRECTING") } })]
        : _vm.activeStep > 0
        ? _c("accessible-stepper", {
            staticClass: "elevation-0",
            attrs: { steps: _vm.steps },
            scopedSlots: _vm._u([
              {
                key: "Login",
                fn: function () {
                  return [
                    _c("login-step", { on: { confirm: _vm.onLoginSuccess } }),
                  ]
                },
                proxy: true,
              },
              {
                key: "Select",
                fn: function () {
                  return [
                    _c("select-step", {
                      attrs: { active: _vm.activeStep === 2 },
                      on: {
                        cancel: _vm.onSelectCanceled,
                        confirm: _vm.onSelectConfirmed,
                      },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "Link",
                fn: function () {
                  return [
                    _c("link-step", {
                      attrs: {
                        active: _vm.activeStep === 3,
                        course: _vm.selectedCourse,
                      },
                      on: { cancel: _vm.onLinkCanceled },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.activeStep,
              callback: function ($$v) {
                _vm.activeStep = $$v
              },
              expression: "activeStep",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }