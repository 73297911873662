var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "accessibleForm",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _vm.formInstruction
        ? _c("p", [_vm._v(_vm._s(_vm.$t("ACCESSIBLE_FORM_FORM_INSTRUCTION")))])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }