var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !!_vm.error
        ? _c(
            "v-alert",
            {
              staticClass: "mb-4",
              attrs: {
                role: "alert",
                type: "error",
                icon: "error",
                dense: "",
                value: !!_vm.error,
              },
            },
            [_vm._v(_vm._s(_vm.error))]
          )
        : [
            _c("h1", [_vm._v(_vm._s(_vm.name))]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-3 mb-3" }, [
              _vm._v(_vm._s(_vm.message)),
            ]),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c("v-spacer"),
            _vm._v(" "),
            _c(
              "v-layout",
              { attrs: { column: "", "align-center": "" } },
              [
                _c("p", { staticClass: "display-3" }, [
                  _vm._v(_vm._s(_vm.cost)),
                ]),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "px-5",
                    attrs: { color: "primary", disabled: _vm.isRedirecting },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.purchaseVendible(_vm.vendibleId)
                      },
                    },
                  },
                  [
                    _vm.isRedirecting
                      ? _c("loading", {
                          attrs: {
                            message: _vm.$t(
                              "VENDIBLE_PAYMENT_BUY_BUTTON_REDIRECTING"
                            ),
                          },
                        })
                      : _c(
                          "span",
                          [
                            _c(
                              "v-icon",
                              { staticClass: "pr-2", attrs: { small: "" } },
                              [_vm._v("shopping_cart")]
                            ),
                            _vm._v(
                              _vm._s(_vm.$t("VENDIBLE_PAYMENT_BUY_BUTTON"))
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "mt-2 px-5",
                    attrs: {
                      color: "primary",
                      text: "",
                      disabled: _vm.isRedirecting,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cancel.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("VENDIBLE_PAYMENT_CANCEL_BUTTON")) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "accessible-expandible",
              {
                staticClass: "mt-6 caption",
                attrs: {
                  title: _vm.$t(
                    "VENDIBLE_PAYMENT_REFUND_POLICY_EXPANSION_HEADER"
                  ),
                },
              },
              [
                _c("div", {
                  staticClass: "mt-2",
                  domProps: { innerHTML: _vm._s(_vm.refundPolicy) },
                }),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }