









import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import StoreGetter from "@interfaces/storeGetter";
import azureLogo from '../../../assets/azure.svg';
import StoreAction from '@/interfaces/storeAction';

@Component({})
export default class MsalLoginButton extends Vue {
    @Getter(StoreGetter.IsLoading) loading: boolean;
    @Action(StoreAction.SetError) setError;
    @Action(StoreAction.Login) login;
    @Action(StoreAction.ResetError) resetError;
    azureLogo = azureLogo;
    authInProgress = false;
    
    buttonStyle(): object {
        return {
            height: '48px',
            'background-color': 'white'
        }
    }

    async msalLogin() {
        this.authInProgress = true;
        try
        {
            const result = await this.$msal.loginPopup({
                scopes: ['user.read'],
                prompt: 'select_account',
            });
            if (await this.login({ provider: "microsoft", token: result.accessToken })) {
                this.$emit("confirm");
                this.resetError();
            } else {
                this.setError({ message: this.$t("LOGIN_UNABLE_TO_LOGIN_TEXT") });
            }
        }
        catch(e)
        {
            this.setError(e);
            TrackJS.track(e);
        }
        finally
        {
            this.authInProgress = false;
        }
    }
}
