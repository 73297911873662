var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", "pb-3": "" } }, [
                        _c("h3", { staticClass: "headline" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t("REGISTRATION_STUDENT_INVITATION_CODE")
                              ) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("REGISTRATION_STUDENT_MESSAGE"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            [
                              _vm.isUSCR ? _c("v-spacer") : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "elevation-0 ml-0",
                                  attrs: {
                                    dark: true,
                                    color: "primary",
                                    "aria-label": _vm.$t(
                                      "REGISTRATION_STUDENT_ACTION_BUTTON"
                                    ),
                                  },
                                  on: { click: _vm.confirm },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.$t(
                                          "REGISTRATION_STUDENT_ACTION_BUTTON"
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }