enum StoreMutation
{
    //AuthenticationStore
	ResetIdentityProvider = "resetIdentityProvider",
    SetToken = "setToken",
    SetLtiIdentity = "setLtiIdentity",
	SetLtiToken = "setLtiToken",
    //EntitlementStore
	SetClaims = "setClaims",
	//ErrorStore
	SetErrorCode = "setErrorCode",
	SetErrorMessage = "setErrorMessage",
	SetErrorDetails = "setErrorDetails",
	//ApiClientStore
	IncrementLoading = "incrementLoading",
	DecrementLoading = "decrementLoading",
	// Payment
	SetPaymentRequiredData = "setPaymentRequiredData",
	SetProcessingPaymentMessage = "setProcessingPaymentMessage",
};

export default StoreMutation;