import Config from '../../config';
import IUserCreator from "./iUserCreator";
import UserCreator from "./userCreator";

export default class CourseCodeUserCreator extends UserCreator implements IUserCreator
{
    constructor(storeContext, private courseCode: string)
    {
        super(storeContext);
    }

    create()
    {
        return this.userApiClient.createUserByCourseCode(this.courseCode)
    }

    async afterCreate()
    {
        window.location.href = `${Config.CYBER_RANGE_UI_BASE_URL}?s=${await this.getSsoToken()}`;
    }
}
