






























import { IVendible, IVendibleApiClient, IVendiblePaymentRequiredError, VendibleType } from '@cyber-range/cyber-range-api-payment-client';
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import LoginButtons from "../login/LoginButtons.vue";

import StoreAction from '@/interfaces/storeAction';
import StoreGetter from "@interfaces/storeGetter";
import Loading from "@utils/Loading.vue";
import AccessibleExpandible from '@components/utils/AccessibleExpandible.vue';

@Component({
    components: { Loading, LoginButtons, AccessibleExpandible }
})
export default class PayVendible extends Vue {
    @Getter(StoreGetter.VendibleApiClient) vendibleApiClient: IVendibleApiClient;
    @Getter(StoreGetter.GetErrorMessage) error;
    @Getter(StoreGetter.IsLogin) isLoggedIn: boolean;
    @Action(StoreAction.SetError) setError;

    // Using this instead of StoreGetter.IsLoading because IsLoading becomes false before page finishes redirecting
    isRedirecting: boolean = false;

    @Watch('$route.query.error', { immediate: true, deep: true })
    async onError(errorCode: string)
    {
        if (!errorCode)
        {
            return;
        }

        this.setError(new Error(this.$t('VENDIBLE_PAYMENT_ERROR_GENERIC').toString()));
    }

    get vendibleId()
    {
        return this.$route.params.vendibleid;
    }

    vendible: IVendiblePaymentRequiredError | IVendible = null;

    @Watch('vendibleId', { immediate: true })
    async onVendibleIdChanged() {
        const vendiblePaymentRequiredError = this.$store.getters[StoreGetter.GetVendiblePaymentRequiredError](this.vendibleId);
        if (vendiblePaymentRequiredError) {
            this.vendible = vendiblePaymentRequiredError;
            return
        }

        if (!this.isLoggedIn) {
            this.$router.push(`/?redirect_url=${this.$route.fullPath}`);
            return;
        }

        const vendible = await this.vendibleApiClient.getOne(this.vendibleId);

        if (!vendible) {
            this.setError(new Error(this.$t('VENDIBLE_NOT_FOUND_ERROR_MESSAGE').toString()));
        }

        this.vendible = vendible;
    }

    get cost(): string
    {
        return "$" + (this.vendible?.price?.amount || 0).toFixed(2);
    }

    get name(): string
    {
        return this.vendible?.name
    }

    get message(): string
    {
        if (this.vendible?.['startTimestamp'] && this.vendible?.['endTimeStamp'])
        {
            const startDate = new Date(this.vendible['startTimestamp']).toDateString();
            const endDate = new Date(this.vendible['endTimeStamp']).toDateString();

            return this.$t('VENDIBLE_PAYMENT_COURSE_ENROLLMENT_MESSAGE', { name: this.name, startDate, endDate }).toString();
        }
        else if ('type' in this.vendible && this.vendible.type === VendibleType.OrganizationBalance)
        {
            return this.$t('VENDIBLE_PAYMENT_ORGANIZATION_BALANCE_MESSAGE').toString();
        }
        else
        {
            return this.$t('VENDIBLE_PAYMENT_GENERIC_MESSAGE', { name: this.name }).toString();
        }
    }

    get refundPolicy()
    {
        if ('type' in this.vendible && this.vendible.type === VendibleType.OrganizationBalance)
        {
            return this.$t('VENDIBLE_PAYMENT_ORGANIZATION_BALANCE_REFUND_POLICY_EXPANSION_CONTENT');
        }
        else
        {
            return this.$t('VENDIBLE_PAYMENT_REFUND_POLICY_EXPANSION_CONTENT');
        }
    }

    async purchaseVendible(vendibleId: string)
    {
        this.isRedirecting = true;
        try
        {
            await this.$store.dispatch(StoreAction.PurchaseVendible, vendibleId)
        }
        catch (error)
        {
            this.isRedirecting = false;
            this.setError(error);
        }
    }

    cancel()
    {
        this.$router.go(-1);
    }
}
