var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "50%", persistent: "" },
      model: {
        value: _vm.selectedValue,
        callback: function ($$v) {
          _vm.selectedValue = $$v
        },
        expression: "selectedValue",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _vm.titleIcon
                ? _c(
                    "span",
                    { staticClass: "titleIcon" },
                    [_vm._t("titleIcon")],
                    2
                  )
                : _vm._e(),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.title || _vm.$t("ARE_YOU_SURE")) +
                  "\n            "
              ),
              _c("v-spacer"),
              _vm._v(" "),
              _vm._t("titleActions"),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-card-text", [_vm._t("default")], 2),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "pl-2 pr-3 pb-3" },
            [
              _vm._t("bottomLeft"),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm._t("bottom"),
              _vm._v(" "),
              _vm.cancel !== false
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "confirm",
                        text: "",
                        disabled:
                          _vm.disabled || _vm.loading || _vm.cancelDisabled,
                      },
                      on: {
                        click: function ($event) {
                          _vm.$emit("cancel")
                          _vm.$emit("input", false)
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.cancel || _vm.$t("CANCEL")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.confirm !== false
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: _vm.confirmColor || "confirm",
                        disabled:
                          _vm.disabled || _vm.loading || _vm.confirmDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("confirm")
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.confirm || _vm.$t("CONFIRM")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._t("bottomRight"),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }