









































































import Vue from 'vue'
import Component from 'vue-class-component';
import { Getter } from "vuex-class";
import StoreApiGetter from "@interfaces/storeGetter";
import MessageSubject from "@interfaces/messageSubject";
import Loading from '@utils/Loading.vue';
import { IContentApiClient, IContentSupportEmail } from '@cyber-range/cyber-range-api-content-client'
import { validateAll } from 'utils/validateAll';

@Component({ components: { Loading }})
export default class UscrOrganizationSignUp extends Vue {
    @Getter(StoreApiGetter.ContentApiClient) contentApiClient: IContentApiClient;
    maxFieldLength: number = 128;
    name: string = '';
    email: string = '';
    organizationName: string = '';
    loading: boolean = false;
    completed: boolean = false;

    requiredTextFieldLabel(value: string, stringKey: string){
        return `${this.$t(stringKey)}${(value === '')? '*': ''}`;
    }

    get messageWithOrganizationDetails(): IContentSupportEmail {
        return {
            subject: MessageSubject.ORGANIZATION_SIGNUP,
            body: `Subject ${MessageSubject.ORGANIZATION_SIGNUP}
                    Name: ${this.name}
                    Email: ${this.email}
                    Organization: ${this.organizationName}`
        };
    }

    async signUp(): Promise<void> {
        if(await validateAll(this) && this.$validator.errors.items.length === 0){
            this.$emit('submitting', true);
            try{
                this.loading = true;
                await this.contentApiClient.sendSupportEmail(this.messageWithOrganizationDetails);
                this.completed = true;
            } catch ( e ) {
            } finally {
                this.loading = false;
            }
        }
    }
}
