var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "expansion-btn pa-0 ma-0",
          attrs: { text: "", "aria-expanded": "" + _vm.isExpanded },
          on: { click: _vm.toggleIsExpanded },
        },
        [
          _c(
            "span",
            { staticClass: "d-inline-block", style: _vm.expansionIconStyle },
            [_vm._v(">")]
          ),
          _vm._v(" " + _vm._s(_vm.title) + "\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpanded,
              expression: "isExpanded",
            },
          ],
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }