



































import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import StoreGetter from "@/interfaces/storeGetter";
import { CourseFilter, CourseStatus, ICourse, IUserApiClient } from "@cyber-range/cyber-range-api-user-client";
import Loading from '@utils/Loading.vue';
import { LtiIdentity } from "@cyber-range/cyber-range-api-authentication-client";

@Component({components: {Loading}})
export default class SelectStep extends Vue {

    @Prop(Boolean) active:boolean;
    @Getter(StoreGetter.IsLoading) isLoading: boolean;
    @Getter(StoreGetter.UserApiClient) userApiClient:IUserApiClient;
    @Getter(StoreGetter.GetLtiIdentity) ltiIdentity:LtiIdentity;
    @Getter(StoreGetter.CanCreateCourseApplication) canCreateCourseApplication:(courseId:string, organizationId:string) => boolean;

    courses:ICourse[] = [];
    selectedCourse:ICourse = <ICourse>{};
    fetchingCourses:boolean = false;
    coursesFetched:boolean = false;

    get canvasCourseName():string
    {
        return this.ltiIdentity['contextName']
    }

    @Watch("active")
    async onActiveChanged()
    {
        if(this.active)
        {
            this.courses = [];
            this.selectedCourse = <any>{}

            try
            {
                this.coursesFetched = false;
                this.fetchingCourses = true;
                let filter = new CourseFilter({organizationIds: [this.ltiIdentity.organizationId]})
                this.courses = (await this.userApiClient.getAllCourses(filter))
                                                        .sort((a,b)=>a.name.localeCompare(b.name, undefined, { sensitivity: "base", numeric: true }))
                                                        .filter(course => this.canCreateCourseApplication(course.id, course.organizationId) && course.status === CourseStatus.Ready)

                this.coursesFetched = true;
            }
            finally
            {
                this.fetchingCourses = false;
            }
        }
    }

    getCourseInstructors(course:ICourse): string
    {
        return (course.statistics?.instructors || []).map(instructor => instructor.name).join(', ')
    }
    
    cancel()
    {
        this.$emit('cancel')
    }

    confirm()
    {
        this.$emit('confirm', this.selectedCourse)
    }
}
