export default {
    en: {
        JOIN_TITLE: "Welcome to <b>{competitionName}</b>",
        JOIN_LOGGING_IN: "Logging in...",
        JOIN_CREATING_ACCOUNT: "Please wait while your account is being prepared...",
        JOIN_COMPETITION_NOT_FOUND: "The competition could not be found or no longer exists.",
        JOIN_FAILED_COMPETITION_ENDED: "Could not join this competition because it has ended.",
        JOIN_FAILED_COMPETITION_PRIVATE: "Could not join this competition because it does not allow public registration."
    }
};
