import 'babel-polyfill';
import { TrackJS } from 'trackjs';
import 'vuetify/dist/vuetify.min.css';
import Main from "@components/Main.vue";
import { initializeAllGlobalFilters } from '@entities/filters/filter';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuetify from 'vuetify';
import Config from '@/config';
import GoogleAuth from './components/login/providers/google';
import AzureAuth from './components/login/providers/azure';
import store from '@/stores/store';
import VeeValidate from 'vee-validate';
import VueMeta from 'vue-meta';

Config.load(`/dist/env.json?t=${new Date().getTime()}`).then(() => 
{
	const router = require('./router').default; //Has to be loaded after config

	Vue.use(VeeValidate);

	// Vuex
	const store = require('@stores/store').default;
	// Vuetify
	Vue.use(Vuetify);

	// Google
	Vue.use(GoogleAuth, { clientId: Config.GOOGLE_CLIENT_ID });

	// Microsoft
	Vue.use(AzureAuth, {
		clientId: Config.AZUREAD_CLIENT_ID,
		redirectUri: new URL('/spa', Config.LOGIN_UI_BASE_URL)
	});

	initializeAllGlobalFilters();
	const createMessages = require('@entities/strings/strings').default; //Has to be loaded after config
	Vue.use(VueI18n);
	const i18n = new VueI18n({
		locale: process.env.locale || 'en',
		messages: createMessages(Config.BASE_DOMAIN)
	});
	Vue.use(VueMeta);
	new Vue({
		el: '#app',
		store,
		router,
		i18n,
		render: (h) => h(Main),
		vuetify: new Vuetify({
			icons: {
				iconfont: 'mdiSvg',
			},
			theme: {
				themes: {
					light: {
						primary: '#273474',
						secondary: '#feb500',
						disabled: '#9E9E9E',
						success: '#4caf50',
						application: '#f2f1ec',
						info: '#42A5F5',
						warning: '#FF9800',
						confirm: '#273474',
						error: '#B71C1C'
					}
				}
			},
			options: {
				customProperties: true
			}
		})
	});
	document.title = Config.APP_TITLE;
});
Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
	// Log properties passed to the component if there are any
	if (vm.$options.propsData) {
		console.log('Props passed to component', vm.$options.propsData);
	}

	// Emit component name and also the lifecycle hook the error occured in if present
	let infoMessage = `Error in component: <${vm.$options['_componentTag']} />\n`;
	if (info) {
		infoMessage += `Error occurred during lifecycle hook: ${info}\n`;
	}

	// This puts the additional error information in the Telemetry Timeline
	console.log(infoMessage);
	console.log(err);
	
	// Track the native JS error
	TrackJS.track(err);
};
