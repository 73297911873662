var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { staticClass: "ma-0 pa-0", attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0 d-flex justify-center align-center" },
                [_c("loading", { attrs: { message: _vm.$t("REDIRECTING") } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }