







import Vue from 'vue';
import Config from '@/config';
import Component from 'vue-class-component';
@Component({})
export default class Announcement extends Vue {
	info: string = Config.ANNOUNCEMENT_INFO;
	warning: string = Config.ANNOUNCEMENT_WARNING;
}
