var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "mb-4",
      attrs: { type: "error", icon: "error", dense: "", value: !!_vm.error },
    },
    [_c("div", [_vm._v("\n        " + _vm._s(_vm.error) + "\n    ")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }