







import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class ErrorText extends Vue {
	@Prop(Boolean) show: boolean;
	@Prop(Boolean) showIcon: boolean;
	@Prop(String) message: string;
}
