







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class AccessibleForm extends Vue {
    @Prop() submitFunction: () => Promise<void>;
    @Prop(Boolean) formInstruction;

    preventEnterSubmit(event)
    {
        const target = event.target;
        // We use inputs that use enter key to interact with them.
        // Ignore enter key from anything except submit button for better UX.
        if (event.key === 'Enter' && target.type !== "submit") 
        {
            event.preventDefault();
        }
    }

    focusFirstError() 
    {
        const form = (this.$refs.accessibleForm as any).$el as HTMLElement;
        // We can't use the :invalid selector here because we aren't doing default validation.
        // Vuetify puts the error--text class on any inputs that fail validation, so use that.
        const firstErrorField = form.querySelector('.v-input.error--text input') as HTMLElement;

        if (firstErrorField)
        {
            firstErrorField.focus();
        }
    }

    mounted()
    {
        this.$el.addEventListener('keydown', this.preventEnterSubmit);
    }
    beforeDestroy()
    {
        this.$el.removeEventListener('keydown', this.preventEnterSubmit);
    }

    async onSubmit() {
        await this.submitFunction();
        this.focusFirstError();
    }
}
