












import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';


@Component
export default class AccessibleExpandible extends Vue {
    @Prop(String) title: string

    isExpanded: boolean = false
    get expansionIconStyle(): string
    {
        if (this.isExpanded)
        {
            return 'transform: rotate(90deg);'
        }
        else 
        {
            return '';
        }
    }

    toggleIsExpanded(){
        this.isExpanded = !this.isExpanded
    }
}
