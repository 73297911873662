export default {
    en: {
        VENDIBLE_PAYMENT_PROCESSING_MESSAGE: 'Please wait while your payment is being processed...',
        VENDIBLE_PAYMENT_ERROR_GENERIC: 'There was an error processing your payment. Please try again later',
        VENDIBLE_NOT_FOUND_ERROR_MESSAGE: 'The vendible could not be found. Please verify your vendible link and try again.',
        VENDIBLE_PAYMENT_GENERIC_MESSAGE: 'Access to {name} requires a one time charge.',
        VENDIBLE_PAYMENT_ORGANIZATION_BALANCE_MESSAGE: 'Apply towards organization balance',
        VENDIBLE_PAYMENT_COURSE_ENROLLMENT_MESSAGE: 'Access to {name} requires a one time charge to allow access from {startDate} to {endDate}.',
        VENDIBLE_PAYMENT_BUY_BUTTON: 'Buy Now',
        VENDIBLE_PAYMENT_BUY_BUTTON_REDIRECTING: 'Redirecting...',
        VENDIBLE_PAYMENT_CANCEL_BUTTON: 'Cancel',
        VENDIBLE_CONFIRMED_NO_DATA: 'This payment has already been processed. Please log in to your account.',
        VENDIBLE_PAYMENT_REFUND_POLICY_EXPANSION_HEADER: 'U.S. Cyber Range of Virginia Tech Policy and Terms',
        VENDIBLE_PAYMENT_REFUND_POLICY_EXPANSION_CONTENT: `<p><b>U.S. Cyber Range of Virginia Tech Refund Policy</b></p>
            <p>
                Once users have logged into the range, the course fees are non-refundable, and course fees will not be reduced. Users who must withdraw from a course for medical, military, or other emergencies will be evaluated and will be reviewed on a case-by-case basis. If you have questions or need assistance, please contact our business office at 540-231-4759 or by email at contact@uscyberrange.org.
            </p>
            <p>
                <b>U.S. Cyber Range of Virginia Tech</b><br>
                1880 Pratt Drive, Suite 2006<br>
                Blacksburg, VA 24060, United States<br>
                <br>
                <a href="https://uscyberrange.org/terms-of-use" target="_blank"><b>Terms of Use/Privacy Policy</b></a>
            </p>`,
        VENDIBLE_PAYMENT_ORGANIZATION_BALANCE_REFUND_POLICY_EXPANSION_CONTENT: `<p><b>U.S. Cyber Range of Virginia Tech Refund Policy</b></p>
            <p>
                Payments are non-refundable for services that have already been provided. For prepaid accounts, a credit to the customer will be provided if the actual usage is below the customer's account balance. Upon customer request, this credit will be returned to the Customer within 60 days of the end of the contract period. Other refund requests will be reviewed on a case-by-case basis. If you have questions or need assistance, please contact our business office at 540-231-4759 or by email at contact@uscyberrange.org. 

            </p>
            <p>
                <b>U.S. Cyber Range of Virginia Tech</b><br>
                1880 Pratt Drive, Suite 2006<br>
                Blacksburg, VA 24060, United States<br>
                <br>
                <a href="https://uscyberrange.org/terms-of-use" target="_blank"><b>Terms of Use/Privacy Policy</b></a>
            </p>`
    }
};
