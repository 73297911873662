import IStoreState from '@interfaces/iStoreState';
import StoreMutation from '@interfaces/StoreMutation';
import StoreAction from '@/interfaces/storeAction';
import StoreGetter from '@/interfaces/storeGetter';

export default {
    state:{
        errorCode: 0,
        errorMessage: undefined,
        errorDetails: undefined
    },
    getters: {
        [StoreGetter.GetErrorCode](state:IStoreState):number{
            return state.errorCode;
        },
        [StoreGetter.GetErrorMessage](state:IStoreState):string{
            return state.errorMessage;
        },
        [StoreGetter.GetErrorDetails](state:IStoreState):string{
            return state.errorDetails;
        }
    },
    mutations: {
        [StoreMutation.SetErrorCode](state:IStoreState, code:number):void {
            state.errorCode = code;
        },
        [StoreMutation.SetErrorMessage](state:IStoreState, message):void {
            state.errorMessage = message;
        },
        [StoreMutation.SetErrorDetails](state:IStoreState, details):void {
            state.errorDetails = details;
        }
    },
    actions: {
        [StoreAction.SetError](context, e){
            if(e)
            {
                let errorMessage = e.message || e.error || JSON.stringify(e);

                // Suppress Bad Gateway error until Sysops fix the root cause.
                if(e.statusCode === 502) return;

                if(e.statusCode === 403 || e.statusCode === 422 || (e.statusCode === 400 && e.message === 'jwt expired'))
                {
                    if(e.message === 'Forbidden' || e.message === 'jwt expired') errorMessage = 'Your session has expired.'
                }
                
                if(errorMessage === 'popup_closed_by_user')
                {
                    errorMessage = 'Could not successfully authenticate with Google because the popup window was closed. Please disable popup blockers or privacy browser extensions and try again.'
                }

                //When a user does not have an account, multiple errors will be generated.
                //Ignore all errors if the current error indicates the user does not have an account.
                if(context.getters.getErrorMessage &&  context.getters.getErrorMessage.indexOf('Your account could not be found') === 0)
                {
                    return;
                }
                
                context.commit(StoreMutation.SetErrorCode, e.statusCode);
                context.commit(StoreMutation.SetErrorMessage, errorMessage);
                context.commit(StoreMutation.SetErrorDetails, e.details);

            }
            else
            {
                context.commit(StoreMutation.SetErrorCode, 0);
                context.commit(StoreMutation.SetErrorMessage, undefined);
                context.commit(StoreMutation.SetErrorDetails, undefined);
            }
        },
        [StoreAction.ResetError](context){
            context.dispatch(StoreAction.SetError, undefined)
        }
    }
};