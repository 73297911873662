export default {
    en: {
        //Setup
        LTI_SETUP_ERROR_CLIENT_ID_NOT_FOUND: 'Unexpected LTI client ID is found.  Please contact your organization admins to complete the configuration by creating an organization application for the LTI client ID in the Cyber Range organization.',
        LTI_SETUP_ERROR_DEPLOYMENT_ID_NOT_FOUND: 'Unexpected LTI deployment ID is found.  Please contact your organization admins to complete the configuration by verifying that the organization application in the Cyber Range organization has the right deployment IDs.',
        LTI_SETUP_ERROR_INVALID_KEY_SIGNING: 'Could not validate the signature of the LTI launch request. Please contact your organization admins to verify the organization application in the Cyber Range organization has the right key set URL.',
        LTI_SETUP_ERROR_INVALID_ROLE: 'Your LTI identity does not have an authorized role to access this resource. Please make sure you are enrolled in this course as a teacher, a teaching assistant, or a student.',
        LTI_SETUP_ERROR_INVALID_ISSUER: 'Unexpected LTI issuer is found.  Please contact your organization admins to complete the configuration by verifying that the organization application in the Cyber Range organization has the right issuer value.',
        LTI_SETUP_ERROR_INVALID_TARGET_URI: 'Unexpected Target link URI found.  Please contact your organization admins to complete the configuration by verifying that the lti configuration has the right target link uri value.',
        LTI_SETUP_ERROR_PII_PROVIDED: 'Your login could not be accepted because it contains personally identifiable information. Please contact your organization admins to configure your LTI platform to ensure user information is not shared.',
        LTI_SETUP_ERROR_TECHNICAL: 'Oops! Something went wrong.  Please try again in a few moment.  If the problem persists, please contact your administrators.',
        LTI_SETUP_ERROR_GENERIC: 'Please contact your organization admins to complete the configuration by configuring an organization application in the Cyber Range organization.',
        LTI_SETUP_ERROR_EVENT_ID: 'Event ID: {eventId}',
        
        //Step 0
        LTI_INVALID_TOKEN: 'Invalid LTI token or the token is expired.',
        LTI_INVALID_IDENTITY: 'Invalid LTI identity.',
        LTI_MISSING_COURSE_APPLICATION: 'This course has not been associated with any Cyber Range course. Please contact your instructor or admins to complete this integration.',
        LTI_REDIRECTING: 'Redirecting...',

        //Step 1 - Login
        LTI_LOGIN_STEP_TITLE: 'Login',
        LTI_LOGIN_STEP_TEXT: "In order to associate this course with a Cyber Range course, please login to your existing Cyber Range account  with the following options below to configure this integration:",
        
        //Step 2 - Select
        LTI_SELECT_SELECT_COURSE_LABEL: 'Course',
        LTI_SELECT_STEP_TITLE: 'Select',
        LTI_SELECT_STEP_TEXT: "Please select a course you want to associate with <b>{canvasCourseName}</b>",
        LTI_SELECT_STEP_CANCEL: "Back",
        LTI_SELECT_STEP_CONFIRM: "Confirm",
        LTI_SELECT_LOADING: 'Loading your courses...',
        LTI_SELECT_NO_COURSES: "This account does not have any courses that can be associated with <b>{canvasCourseName}</b>. Please login with a different account.",

        //Step 3 - Link
        LTI_LINK_STEP_TITLE: 'Link',
        LTI_LINK_STEP_LOADING: "Processing...",
        LTI_LINK_STEP_SUCCEEDED_TEXT: "<b>{ltiContextName}</b> has been successfully linked to <b>{courseName}</b>",
        LTI_LINK_STEP_FAILED_TEXT: "Could not associate this course with the Cyber Range course. Please retry again or contact your admins for assistance.",
        LTI_LINK_STEP_CANCEL: "Back",
        LTI_LINK_STEP_CONFIRM: "Launch",
        LTI_LINK_STEP_LAUNCH_FAILED: 'Your session may have expired. Please reload this page to continue.'
    }
};
