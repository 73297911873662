import * as Claims from '@cyber-range/cyber-range-shared-claims';
import IStoreGetter from '@interfaces/iStoreGetter';
import IStoreState from '@interfaces/iStoreState';
import StoreGetter from '@interfaces/storeGetter';

export default {
	getters: {
		[StoreGetter.CanListCourses]: (state: IStoreState, getters: IStoreGetter) => (resourceIds: string[]) => {
			return getters.hasClaim(Claims.Type.API, Claims.Value.LIST_V1_COURSES, [
				...resourceIds,
				Claims.Scope.UNDEFINED,
				Claims.Scope.ANY
			]);
		},
		[StoreGetter.CanManageOrganization]: (state: IStoreState, getters: IStoreGetter) => (organizationIds: string[]) => {
			return getters.hasClaim(
				Claims.Type.CAN_MANAGE_ORGANIZATION,
				[Claims.Value.ANY, ...organizationIds],
				[...organizationIds, Claims.Scope.UNDEFINED, Claims.Scope.ANY]
			);
		},
		[StoreGetter.CanViewExerciseArea]: (state: IStoreState, getters: IStoreGetter) =>
		{
			let scopes = getters[StoreGetter.Scopes];
			return getters.isLogin && (getters.canManageOrganization(scopes) || getters.canListCourses(scopes));
		},
		[StoreGetter.CanViewAllCompetitions]: (state: IStoreState, getters: IStoreGetter) => (organizationId: string) =>
		{
			return getters.hasClaim(Claims.Type.CAN_VIEW_ALL_COMPETITIONS, [Claims.Value.ANY,organizationId], [
				organizationId,
				Claims.Scope.UNDEFINED,
				Claims.Scope.ANY
			]);
		},
		[StoreGetter.CanAccessCompetition]: (state: IStoreState, getters: IStoreGetter) => (competitionId:string, organizationId:string) =>
		{
			//If the user has this ctf membership, good.
			if(getters.hasClaim(Claims.Type.CTF_COMPETITION,competitionId,[Claims.Scope.ANY,competitionId]))
			{
				return true;
			}

			//If the user can view this organization's competitions, good. (e.g. courseadmin and superadmin)
			if(getters.canViewAllCompetitions(organizationId))
			{
				return true;
			}

			return false;
		},
		[StoreGetter.CanRegisterAsAdmin]: (state: IStoreState, getters: IStoreGetter) => (courseId: string) => {
			return getters.hasClaim(Claims.Type.CAN_REGISTER_CTF_ADMIN, courseId, [
				courseId,
				Claims.Scope.UNDEFINED,
				Claims.Scope.ANY
			]);
		},
		[StoreGetter.CanRegisterAsPlayer]: (state: IStoreState, getters: IStoreGetter) => (courseId: string) => {
			return getters.hasClaim(Claims.Type.CAN_REGISTER_CTF_PLAYER, courseId, [
				courseId,
				Claims.Scope.UNDEFINED,
				Claims.Scope.ANY
			]);
		},
		[StoreGetter.CanCreateCourseApplication]: (state: IStoreState, getters: IStoreGetter) => (courseId: string, organizationId: string) => {
			return getters.hasClaim(
				Claims.Type.API,
				Claims.Value.POST_V1_COURSE_APPLICATION,
				[courseId, organizationId, Claims.Scope.UNDEFINED, Claims.Scope.ANY]
			);
		},
	}
};
