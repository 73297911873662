import IPaymentRequiredData from '@/interfaces/iPaymentRequiredData';
import IPaymentProcessor from './iPaymentProcessor';
import StoreAction from '@/interfaces/storeAction';
import StoreGetter from '@/interfaces/storeGetter';
import NoPaymentSessionDataFoundError from '@/entities/errors/noPaymentSessionDataFoundError';

export default class UserPaymentProcessor implements IPaymentProcessor {

    message = 'Please wait while your account is being prepared...';

    constructor(private store, private paymentData: IPaymentRequiredData) {}

    async process() {
        const userToken: string = this.store.getters[StoreGetter.GetToken];

        window.sessionStorage.removeItem(this.paymentData.vendibleId);

        if (!userToken) {
            throw new NoPaymentSessionDataFoundError();
        }

        await this.store.dispatch(StoreAction.CreateUser, {
            method: this.paymentData.source.method,
            args: this.paymentData.source.args
        });
    }
}
