












import Vue from "vue";
import { Base64 } from "js-base64";
import StoreAction from "@interfaces/storeAction";
import { Action } from "vuex-class";
import { Prop, Component } from "vue-property-decorator";

@Component({})
export default class BasicAuth extends Vue {
    @Action(StoreAction.Login) login;
    @Prop() source;
    drawer = null;
    username = "";
    password = "";
    isLoggingIn = false;
    error = "";
    async keyUp(e) {
        if (e.keyCode === 13) this.submit();
    }
    async submit() {
        try {
            this.isLoggingIn = true;

            if (await this.login({ provider: "basic", token: Base64.encode(`${this.username}:${this.password}`) })) {
                this.$store.dispatch(StoreAction.ResetError);
                this.$emit("confirm");
            } else {
                this.$store.dispatch(StoreAction.SetError, { message: this.$t("LOGIN_INVALID_CREDENTIALS") });
            }
        } finally {
            this.isLoggingIn = false;
        }
    }
}
