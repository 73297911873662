











import Vue from "vue";
import StoreAction from "@interfaces/storeAction";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import StoreGetter from "@/interfaces/storeGetter";
import * as uuid from 'uuid';
import { Prop } from "vue-property-decorator";

@Component({
    components: {  }
})
export default class LtiError extends Vue 
{
    @Prop(String) code:string;

    @Getter(StoreGetter.GetErrorMessage) error;
    @Action(StoreAction.SetError) setError: (e:Error)=>void;

    private _eventId:string;
    get eventId():string
    {
        return this._eventId || (this._eventId = uuid['validate'](this.$route.query.eid) ? <string>this.$route.query.eid : undefined);
    }

    async created()
    {
        let errorMessage:string = '';

        switch(this.code)
        {
            case 'clientidnotfound': 
                errorMessage = this.$t('LTI_SETUP_ERROR_CLIENT_ID_NOT_FOUND').toString();
                break;
            case 'deploymentidnotfound': 
                errorMessage = this.$t('LTI_SETUP_ERROR_DEPLOYMENT_ID_NOT_FOUND').toString();
                break;
            case 'invalidkeysigning': 
                errorMessage = this.$t('LTI_SETUP_ERROR_INVALID_KEY_SIGNING').toString();
                break;
            case 'invalidrole': 
                errorMessage = this.$t('LTI_SETUP_ERROR_INVALID_ROLE').toString();
                break;
            case 'invalidissuer':
                errorMessage = this.$t('LTI_SETUP_ERROR_INVALID_ISSUER').toString();
                break;
            case 'invalidtargeturi':
                errorMessage = this.$t('LTI_SETUP_ERROR_INVALID_TARGET_URI').toString();
                break;
            case 'piiprovided':
                errorMessage = this.$t('LTI_SETUP_ERROR_PII_PROVIDED').toString();
                break;
            case 'technical':
                errorMessage = this.$t('LTI_SETUP_ERROR_TECHNICAL').toString();
                break;
            default: 
                errorMessage = this.$t('LTI_SETUP_ERROR_GENERIC').toString();
                break;
        }

        this.setError(new Error(errorMessage));
    }
}
