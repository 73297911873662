
















import Vue from "vue";
import StoreAction from "@interfaces/storeAction";
import Component from "vue-class-component";
import ConfirmationDialog from './ConfirmationDialog.vue';
import { Prop } from "vue-property-decorator";

@Component({
    components: {
        ConfirmationDialog
    }
})
export default class ErrorDialog extends Vue {
    @Prop(String) error: String;

    clearError() {
        this.$store.dispatch(StoreAction.ResetError);
        this.$emit("confirm", undefined);
        this.$emit("input", false);
    }
}
