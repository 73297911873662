












import Loading from '@/components/utils/Loading.vue'
import StoreAction from '@/interfaces/storeAction';
import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
    components: { Loading }
})
export default class Redirect extends Vue {
    async created() {
        const redirectUrl = Array.isArray(this.$route.query['redirecturl'])
            ? this.$route.query['redirecturl'][0]
            : this.$route.query['redirecturl'];

        if (!redirectUrl) {
            this.$router.push('/');
            return;
        }

        const token = this.$route.query['s'];
        if (token) {
            await this.$store.dispatch(StoreAction.Login, {provider: 'sso', token });
        }

        window.location.assign(redirectUrl)
    }
}
