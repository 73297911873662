import Config from '@/config';
import StoreGetter from '@interfaces/storeGetter';

export default {
	getters: {
		[StoreGetter.GoogleLoginEnabled](): boolean {
		return Config.FEATURE_LOGIN_GOOGLE_ENABLED;
		},
		[StoreGetter.AzureAdLoginEnabled](): boolean {
			return Config.FEATURE_LOGIN_AZUREAD_ENABLED;
		},
		[StoreGetter.CanCreateBusinessUnitWithAccountRequest](): boolean {
			return Config.FEATURE_BUSINESS_UNIT_CREATION_ENABLED !== false;
		}
	}
};
