



















import Vue from "vue";
import StoreAction from "@interfaces/storeAction";
import { setTimeout } from "timers";
import Loading from "@utils/Loading.vue";
import { GoneApiClientError, NotFoundApiClientError } from "@cyber-range/cyber-range-api-client";
import LoginButtons from "../login/LoginButtons.vue";
import Announcement from "@utils/Announcement.vue";
import { Action, Getter } from "vuex-class";
import Component from "vue-class-component";
import StoreGetter from "@interfaces/storeGetter";
import { InvitationStatus, IInvitation } from '@cyber-range/cyber-range-api-invitation-client';
import Config from '@/config';
import { ICompetitionApiClient } from '@cyber-range/cyber-range-api-ctf-competition-client';
import AddUserMethod from "@/interfaces/addUserMethod";
import { CreateUserParams } from '../../utils/userCreation/createUserParams'

@Component({
    components: { Loading, LoginButtons, Announcement }
})
export default class Invitation extends Vue {
    @Action(StoreAction.SetError) setError;
    @Getter(StoreGetter.InvitationApiClient) invitationApiClient;
    @Getter(StoreGetter.UserApiClient) userApiClient;
    @Getter(StoreGetter.CompetitionApiClient) competitionApiClient: ICompetitionApiClient;
    @Action(StoreAction.GetSsoToken) getSsoToken: () => Promise<string>;
    @Action(StoreAction.TryLogin) tryLogin: () => Promise<boolean>;
    @Getter(StoreGetter.GetErrorMessage) error;
    invitation :IInvitation = <IInvitation> {};
    creatingUser: boolean = false;
    retryableError: boolean = true;

    get invitationId() {
        return this.$route.params.iid;
    }

    async created() {
        try {
            this.invitation = await this.invitationApiClient.getOne(this.invitationId);
            if(this.invitation.status === InvitationStatus.PendingApproval || this.invitation.status === InvitationStatus.Rejected){
                let e : Error = new Error();
                e.message = this.invitation.status === InvitationStatus.PendingApproval
                            ? <string>this.$root.$t('INVITATION_PENDING_APPROVAL_MESSAGE')
                            : <string>this.$root.$t('INVITATION_REJECTED_MESSAGE');
                this.$router.push({ name: "login" });
                this.retryableError = false;
                this.setError(e);
            }
        } catch (e) {
            if (e instanceof GoneApiClientError) {
                e.message = <string>this.$root.$t('INVITATION_ACCEPTED_ERROR_MESSAGE');
                this.$router.push({ name: "login", query: { redirect_url: e.location } });
            } else if (e instanceof NotFoundApiClientError) {
                e.message = <string>this.$root.$t('INVITATION_NOT_FOUND_ERROR_MESSAGE');
            }

            this.retryableError = false;
            this.setError(e);
        }
    }

    async onLoginSuccess() {
        try {
            this.creatingUser = true;

            await this.$store.dispatch(StoreAction.CreateUser, new CreateUserParams(AddUserMethod.invitation, this.invitation));

        } catch(e) {
            //Do not set `creatingUser` back to true if all goes well. 
            //This is so that the loading kept showing until page redirection below.
            this.creatingUser = false; 

            if (e instanceof NotFoundApiClientError) {
                e.message = <string>this.$root.$t('COMPETITION_NOT_FOUND_ERROR_MESSAGE');
            }
            else
            {
                return;
            }
            this.retryableError = false;
            this.setError(e);
        }
    }
}
