export default {
    en: {
        LOGIN_TITLE: {
            VACR: 'Virginia Cyber Range',
            USCR: 'Cyber Range'
        },
        LOGIN_BASIC_LOGIN: "Sign in with Basic",
        LOGIN_INVITATION_LOGIN_TEXT: "Have an invitation code?",
        LOGIN_ACCOUNT_NOT_FOUND: "Your account could not be found. Please make sure you select the right account to sign in or contact your organization admin for an account.",
        LOGIN_AZURE:'Azure',
        LOGIN_GOOGLE:'Google',
        LOGIN_GOOGLE_TEXT: "Sign in with Google",
        LOGIN_GOOGLE_PRIVACY_BLOCKER_TEXT: "Turn off a privacy blocker to sign in with Google",
        LOGIN_AZURE_AD_TEXT: "Sign in with Azure AD",
        LOGIN_CREDENTIAL_USER: 'Sign in with a username/password',
        LOGIN_CREDENTIAL_ANONYMOUS: 'Sign in with an anonymous password',
        LOGIN_INVALID_CREDENTIALS: "Invalid username or password.",
        LOGIN_UNABLE_TO_LOGIN_TEXT: "Unable to login at the moment.  Please try again later.",
        INVALID_REDIRECT_URL: "The given redirect URL '{url}' is not allowed by the application configuration.",
        LOGIN_TEXT: 'Please login with one of the following options or contact your organization for an account.',
        LOGIN_ERROR_HELP_MESSAGE: "Having trouble logging in?",
        LOGIN_ANONYMOUS_USER_HELP_MESSAGE: "What is an anonymous password?",
        LOGIN_GOOGLE_UNABLE_TO_GET_ID_TOKEN: "Unable to login with Google at the moment (missing id_token)  Please try again later."
    }
};
