var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("loading", { attrs: { message: _vm.message } })
    : _c("login")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }