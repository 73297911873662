import { get } from 'http';

export default class Config {

	//Account Request
	public static APP_TITLE = 'The Cyber Range';
	public static LOCAL_STORAGE_VERSION_NUMBER = process.env.LOCAL_STORAGE_VERSION_NUMBER || "1.00";

	// Apis
	public static CYBER_RANGE_UI_BASE_URL = process.env.CYBER_RANGE_UI_BASE_URL || 'http://console.vacr.io:8080';
	public static CYBER_RANGE_UI_USCR_BASE_URL = process.env.CYBER_RANGE_UI_USCR_BASE_URL || 'http://www.uscyberrange.org';
	public static HOMEPAGE_UI_BASE_URL = process.env.HOMEPAGE_UI_BASE_URL || 'http://home.vacr.io:8079';
	public static LOGIN_UI_BASE_URL = process.env.LOGIN_UI_BASE_URL || 'http://localhost:8078';
	public static CTF_UI_BASE_URL = process.env.CTF_UI_BASE_URL || 'http://www.ctf.vacr.io:8077/';
	public static CTF_UI_BASE_DOMAIN = process.env.CTF_UI_BASE_DOMAIN || 'ctf.vacr.org:8077/'
	public static AUTHENTICATION_API_BASE_URL = process.env.AUTHENTICATION_API_BASE_URL || "http://localhost:8081";
	public static ENTITLEMENT_API_BASE_URL = process.env.ENTITLEMENT_API_BASE_URL || 'http://localhost:8082';
	public static USER_API_BASE_URL = process.env.USER_API_BASE_URL || 'http://localhost:8083/';
	public static INVITATION_API_BASE_URL = process.env.INVITATION_API_BASE_URL || 'http://localhost:8086/';
	public static COMPETITION_API_BASE_URL = process.env.COMPETITION_API_BASE_URL || 'http://localhost:8100/';
	public static PAYMENT_API_BASE_URL = process.env.PAYMENT_API_BASE_URL || 'http://localhost:8112/';
	public static BALANCE_API_BASE_URL = process.env.BALANCE_API_BASE_URL || 'http://localhost:8113/';
	public static CONTENT_API_BASE_URL = process.env.CONTENT_API_BASE_URL || 'http://host.vacr.org:8095/';
	public static BUSINESS_UNIT_API_BASE_URL = process.env.BUSINESS_UNIT_API_BASE_URL || 'http://localhost:8094/';
	public static ORGANIZATION_API_BASE_URL = process.env.ORGANIZATION_API_BASE_URL || 'http://localhost:8087/';
	public static NEWSLETTER_API_BASE_URL = process.env.NEWSLETTER_API_BASE_URL || 'http://localhost:8099/';
	public static SUPPORT_URL = process.env.SUPPORT_URL || 'https://kb.virginiacyberrange.org';
	public static LOGIN_SUPPORT_URL = process.env.LOGIN_SUPPORT_URL;
	public static COGNITO_USER_POOL_BASE_URL = process.env.COGNITO_USER_POOL_BASE_URL || "https://poc-user-direct-login.auth.us-east-1.amazoncognito.com";
	public static COGNITO_ANONYMOUS_POOL_BASE_URL = process.env.COGNITO_ANONYMOUS_POOL_BASE_URL || "https://poc-anonymous-users.auth.us-east-1.amazoncognito.com";
	public static VUE_RECAPTCHA_SITEKEY = process.env.VUE_RECAPTCHA_SITEKEY || "secret_key_123";

	public static readonly BASE_DOMAIN = process.env.BASE_DOMAIN || 'USCR';
	public static readonly BASIC_LOGIN_ENABLED = false;
	public static readonly CREDENTIAL_USER_LOGIN_ENABLED = true;
	public static readonly FEATURE_LOGIN_GOOGLE_ENABLED = true;
	public static readonly FEATURE_LOGIN_AZUREAD_ENABLED = true;
	public static GOOGLE_CLIENT_ID = '';
	public static AZUREAD_CLIENT_ID = '';
	public static REDIRECT_URLS = '';
	public static COGNITO_USER_POOL_CLIENT_ID = process.env.COGNITO_USER_POOL_CLIENT_ID || '5fnh3vm9b6fp4vsp7oldnhmvp6';
	public static COGNITO_ANONYMOUS_POOL_CLIENT_ID = process.env.COGNITO_ANONYMOUS_POOL_CLIENT_ID || '6svrkh40obhkodpd2no2oor8vk';

	public static TERMS_AND_POLICIES_URL = '';

	public static TEMPLATE_VARIABLES = {}
	public static ANNOUNCEMENT_INFO = process.env.ANNOUNCEMENT_INFO || '';
	public static ANNOUNCEMENT_WARNING = process.env.ANNOUNCEMENT_WARNING || '';

	// Features
	public static FEATURE_SIGN_IN_AS_ASNONYMOUS_USER_ENABLED:boolean;
	public static FEATURE_BUSINESS_UNIT_CREATION_ENABLED: boolean;

	public static async load(remotePath: string): Promise<void> {
		const file = await Config.getFile(remotePath);
		const json = JSON.parse(file);
		Config.init(json);
	}

	public static async getFile(url: string): Promise<any> {
		return new Promise((resolve, reject) => {
			get(url).on('response', (response) => {
				let body = '';
				response.on('data', (chunk) => {
					body += chunk;
				});
				response.on('end', () => {
					return resolve(body);
				});
				response.on('error', (e) => {
					return reject(e);
				});
			});
		});
	}

	public static init(json: object) {
		Object.keys(json).forEach(function(key) {
			Config[key] = json[key];
		});
	}
}
