var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "credential-login-button",
    _vm._g(
      _vm._b(
        {
          attrs: {
            provider: "credential_user",
            cognitoBaseUrl: _vm.cognitoBaseUrl,
            cognitoClientId: _vm.cognitoClientId,
            icon: "lock_outlined",
            label: _vm.$t("LOGIN_CREDENTIAL_USER"),
          },
        },
        "credential-login-button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }