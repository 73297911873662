


























import Vue from "vue";
import StoreAction from "@interfaces/storeAction";
import Component from "vue-class-component";
import StoreGetter from "@interfaces/storeGetter";
import { Getter, Action } from "vuex-class";
import AccessibleStepper from '../../utils/AccessibleStepper.vue';
import LoginStep from './steps/LoginStep.vue';
import SelectStep from './steps/SelectStep.vue';
import Announcement from '@utils/Announcement.vue';
import { Watch } from "vue-property-decorator";
import { Course, ICourse, IUserApiClient, UserRole } from "@cyber-range/cyber-range-api-user-client";
import { ILtiIdentity, IAuthenticationApiClient } from '@cyber-range/cyber-range-api-authentication-client';
import Config from "@/config";
import LinkStep from './steps/LinkStep.vue';
import Loading from '@utils/Loading.vue';
import { CreateUserParams } from "@/utils/userCreation/createUserParams";
import AddUserMethod from "@/interfaces/addUserMethod";

@Component({
    components: {  AccessibleStepper, Announcement, LoginStep, SelectStep, LinkStep, Loading }
})
export default class Lti extends Vue 
{
    @Getter(StoreGetter.GetErrorMessage) error;
    @Action(StoreAction.SetError) setError;
    @Action(StoreAction.ResetError) resetError;
    @Getter(StoreGetter.GetLtiIdentity) ltiIdentity:ILtiIdentity;
    @Getter(StoreGetter.GetLtiToken) ltiToken:string;
    @Getter(StoreGetter.UserApiClient) userApiClient:IUserApiClient;
    @Getter(StoreGetter.AuthenticationApiClient) authenticationApiClient:IAuthenticationApiClient;
    @Action(StoreAction.GetSsoToken) getSsoToken: () => Promise<string>;
    @Action(StoreAction.Login) login: (payload: { token, provider: 'vcr' }) => Promise<boolean>;
    @Action(StoreAction.LoadingBegin) loadingBegin: () => void;
    
    activeStep = 0;
    steps = [ this.$t('LTI_LOGIN_STEP_TITLE'), this.$t('LTI_SELECT_STEP_TITLE'), this.$t('LTI_LINK_STEP_TITLE') ]

    redirecting = false;
    selectedCourse:ICourse = new Course();

    @Watch('activeStep')
    async onActiveStepChanged()
    {
        await this.resetError();
    }

    async created()
    {
        if(!this.ltiIdentity.sub && !this.error)
        {
            this.setError(new Error(this.$t('LTI_INVALID_IDENTITY').toString()))
        }

        if(this.ltiIdentity.courseApplicationId)
        {
            this.redirectToCourse()
        }
        else
        {
            let roles = this.ltiIdentity.roles;

            //Has to do a role check here because at this point we do not have the Cyber Range identity so a claim check is not possible.
            if(roles.includes(UserRole.Instructor) || roles.includes(UserRole.CourseAdmin) || roles.includes(UserRole.SuperAdmin))
            {
                this.activeStep = 1;
            }
            else
            {
                this.setError(new Error(this.$t('LTI_MISSING_COURSE_APPLICATION').toString()))
            }
        }
        
    }

    async redirectToCourse()
    {
        try
        {
            this.redirecting = true;

            if(await this.login({provider:'vcr', token: this.ltiToken}))
            {
                if(this.ltiIdentity.roles.some(role => [UserRole.Instructor, UserRole.Student, UserRole.TA].includes(<any>role)))
                {
                    await this.$store.dispatch(StoreAction.CreateUser, new CreateUserParams(AddUserMethod.lti, {
                        ltiToken: this.ltiToken,
                        courseId: this.ltiIdentity.courseId,
                    }));
                }
                else
                {
                    let ssoToken = await this.getSsoToken();

                    // Disable all buttons while the user is being redirected to the destination.
                    this.loadingBegin();

                    window.location.href = new URL(`/courses/${this.ltiIdentity.courseId}?s=${ssoToken}`, Config.CYBER_RANGE_UI_BASE_URL).href;
                }
            }
        }
        finally
        {
            this.redirecting = false;
        }
    }

    onLoginSuccess(a)
    {
        this.activeStep++
    }

    onSelectCanceled()
    {
        this.activeStep--;
    }

    async onLinkCanceled()
    {
        this.activeStep--;
        this.selectedCourse = new Course();
    }
    async onSelectConfirmed(selectedCourse:ICourse)
    {
        this.activeStep++;
        this.selectedCourse = selectedCourse;
    }
}
