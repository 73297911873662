import Vue from 'vue';

export function initializeAllGlobalFilters() {
	const filters = [
		
	];

	for (const filter of filters) {
		Vue.filter(filter.id, filter.definition);
	}
}
