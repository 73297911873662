import AddUserMethod from '@/interfaces/addUserMethod';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import Config from '../../config';
import { CreateUserArgs } from './createUserArgs';
import IUserCreator from "./iUserCreator";
import UserCreator from "./userCreator";

export default class DirectJoinUserCreator extends UserCreator implements IUserCreator
{
    private courseId: string;
    private userId: string;
    private role: UserRole

    constructor(storeContext, data: CreateUserArgs<AddUserMethod.directJoin>)
    {
        super(storeContext);
        this.courseId = data.courseId;
        this.userId = data.userId;
        this.role = data.role;
    }

    create()
    {
        return this.userApiClient.addCourseUser(this.courseId, this.userId, this.role);
    }

    async afterCreate()
    {
        window.location.href = new URL(`/courses/${this.courseId}?s=${await this.getSsoToken()}`, Config.CYBER_RANGE_UI_BASE_URL).href;
    }
}
