





















import Config from "@/config";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Page extends Vue {
    get policyUrl()
    {
        return Config.TERMS_AND_POLICIES_URL;
    }
}
