var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _vm.isLinking
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mb-5": "", centeredIcon: "" } },
                  [
                    _c("loading", {
                      attrs: { message: _vm.$t("LTI_LINK_STEP_LOADING") },
                    }),
                  ],
                  1
                ),
              ]
            : _vm.successfullyLinked
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mb-5": "", centeredIcon: "" } },
                  [
                    _c("v-icon", { attrs: { size: "48", color: "success" } }, [
                      _vm._v("done"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-flex", { attrs: { xs12: "", "mb-5": "" } }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("LTI_LINK_STEP_SUCCEEDED_TEXT", {
                          ltiContextName: _vm.ltiIdentity.contextName,
                          courseName: _vm.course.name,
                        })
                      ),
                    },
                  }),
                ]),
              ]
            : [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "mb-5": "", centeredIcon: "" } },
                  [
                    _c("v-icon", { attrs: { size: "48", color: "error" } }, [
                      _vm._v("error"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-flex", { attrs: { xs12: "", "mb-5": "" } }, [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("LTI_LINK_STEP_FAILED_TEXT")),
                    },
                  }),
                ]),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        { staticClass: "pl-2 pr-3 pb-3" },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _vm.isLinking || _vm.launchFailed
            ? void 0
            : _vm.successfullyLinked
            ? _c(
                "v-btn",
                {
                  staticClass: "elevation-0",
                  attrs: { color: "primary", disabled: _vm.isLoading },
                  on: { click: _vm.confirm },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("LTI_LINK_STEP_CONFIRM")) +
                      "\n        "
                  ),
                ]
              )
            : _c(
                "v-btn",
                {
                  attrs: {
                    color: "confirm",
                    text: "",
                    disabled: _vm.isLoading,
                  },
                  on: { click: _vm.cancel },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("LTI_LINK_STEP_CANCEL")) +
                      "\n        "
                  ),
                ]
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }