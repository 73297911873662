







































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component({})
export default class ConfirmationDialog extends Vue {
    // Until rewriting correctly, using Prop() is equivalient to any type.
    // For some reason we use cancel and confirm with many different types.
    @Prop() cancel;
    @Prop() confirm;
    @Prop(String) title: string;
    @Prop(Boolean) disabled: boolean;
    @Prop(Boolean) value: string;
    @Prop(Boolean) loading: boolean;
    @Prop(Boolean) cancelDisabled: boolean;
    @Prop(Boolean) confirmDisabled: boolean;
    @Prop(String) confirmColor: string;

    isOpen: boolean = true;
    get selectedValue(): boolean {
        if (this.value) {
            return true;
        } else {
            return false;
        }
    }
    get titleIcon() {
        return this.$slots["titleIcon"];
    }
}
