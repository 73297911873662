var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.creatingUser
    ? _c("loading", {
        attrs: { message: _vm.$t("INVITATION_LOADING_MESSAGE") },
      })
    : _vm.error && !_vm.retryableError
    ? _c(
        "v-alert",
        {
          staticClass: "mb-4",
          attrs: {
            role: "alert",
            type: "error",
            icon: "error",
            dense: "",
            value: _vm.error,
          },
        },
        [_vm._v(_vm._s(_vm.error))]
      )
    : _c(
        "div",
        { staticClass: "elevation-0" },
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("INVITATION_CONFIRMATION")))]),
          _vm._v(" "),
          _vm.invitation.id
            ? [
                _vm.error && _vm.retryableError
                  ? _c(
                      "v-alert",
                      {
                        staticClass: "mb-4",
                        attrs: {
                          role: "alert",
                          type: "error",
                          icon: "error",
                          dense: "",
                          value: _vm.error,
                        },
                      },
                      [_vm._v(_vm._s(_vm.error))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("announcement"),
                _vm._v(" "),
                _c("p", { staticClass: "mt-3 mb-3" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("INVITATION_WELCOME_TEXT", {
                        name: _vm.invitation.name,
                      })
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("v-spacer"),
                _vm._v(" "),
                _c("v-spacer"),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.invitation.id
            ? [
                _c("login-buttons", {
                  attrs: {
                    mode: "invitations",
                    providers: ["google", "azure"],
                  },
                  on: { confirm: _vm.onLoginSuccess },
                }),
              ]
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }