var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "span",
        [
          _vm.showIcon !== false
            ? _c("v-icon", { staticClass: "pr-1", attrs: { color: "error" } }, [
                _vm._v("error"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "error--text text--darken-1" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }