var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      attrs: { "aria-live": "polite" },
      model: {
        value: _vm.activeStep,
        callback: function ($$v) {
          _vm.activeStep = $$v
        },
        expression: "activeStep",
      },
    },
    [
      _c(
        "v-stepper-header",
        [
          _vm._l(_vm.steps, function (step, i) {
            return [
              _c(
                "v-stepper-step",
                {
                  key: "step-" + i + "-headder",
                  attrs: {
                    id: "step-" + i + "-header",
                    complete: _vm.activeStep > i + 1,
                    step: i + 1,
                    "complete-icon": "check",
                  },
                },
                [
                  _vm.activeStep > i + 1
                    ? _c("span", { staticClass: "visually-hidden" }, [
                        _vm._v("Complete: "),
                      ])
                    : _vm.activeStep === i + 1
                    ? _c("span", { staticClass: "visually-hidden" }, [
                        _vm._v("Current: "),
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n                " + _vm._s(step) + "\n            "
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-stepper-items",
        _vm._l(_vm.steps, function (step, i) {
          return _c(
            "v-stepper-content",
            {
              key: i + "-step",
              attrs: {
                step: i + 1,
                "aria-labelledby": "step-" + i + "-header",
              },
            },
            [_vm._t(step.replace(" ", "-"))],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }