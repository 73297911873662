








import Vue from "vue";
import StoreAction from "@interfaces/storeAction";
import Component from "vue-class-component";
import { Getter, Action } from "vuex-class";
import StoreGetter from "@/interfaces/storeGetter";

@Component
export default class Logout extends Vue 
{
    @Getter(StoreGetter.GetErrorMessage) error;
    @Action(StoreAction.SetError) setError: (e:Error)=>void;

    async created()
    {
        this.setError(new Error(this.$t('LOGOUT_MESSAGE').toString()));
    }
}
