

































import Vue from "vue";
import BasicAuth from "./providers/BasicAuth.vue";
import GoogleSignInButton from "./providers/GoogleSignInButton.vue";
import MsalLoginButton from "./providers/MsalLoginButton.vue";
import CredentialUserLoginButton from "./providers/CredentialUserLoginButton.vue";
import CredentialAnonymousLoginButton from "./providers/CredentialAnonymousLoginButton.vue";
import Config from "@/config";
import StoreGetter from "@interfaces/storeGetter";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import StoreAction from '@/interfaces/storeAction';
import { resolve } from 'url';

@Component({
    components: { BasicAuth, GoogleSignInButton, MsalLoginButton, CredentialUserLoginButton, CredentialAnonymousLoginButton }
})
export default class LoginButtons extends Vue {
    @Getter(StoreGetter.IsLoading) loading: boolean;
    @Prop() text:string;
    @Prop() mode: string;
    @Prop() competitionId: string;
    @Prop() credentialCode;
    @Prop() providers: string[];
    @Getter(StoreGetter.GetIdentityProvider) identityProvider;
    @Getter(StoreGetter.GoogleLoginEnabled) googleLoginEnabled;
    @Getter(StoreGetter.AzureAdLoginEnabled) azureAdLoginEnabled;
    @Mutation(StoreAction.ResetIdentityProvider) resetIdentityProvider;

    get newAccountLink() {
        return Config.BASE_DOMAIN === 'VACR' ? resolve(Config.CYBER_RANGE_UI_BASE_URL, '/signup?code=vacr') : resolve(Config.HOMEPAGE_UI_BASE_URL, '/contact-us');
    }
    
    get showBasicLogin() {
        return Config.BASIC_LOGIN_ENABLED;
    }

    get featureSignInAsAnonymousUserEnabled():boolean
    {
        return !!Config.FEATURE_SIGN_IN_AS_ASNONYMOUS_USER_ENABLED;
    }

    get credentialUserLoginEnabled(): boolean {
        return Config.CREDENTIAL_USER_LOGIN_ENABLED;
    }

    confirm() {
        this.$emit("confirm");
    }
    showAllLoginOptions() {
        this.resetIdentityProvider();
    }
}
