var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "confirmation-dialog",
    {
      attrs: {
        title: _vm.$t("ERROR"),
        "max-width": "50%",
        cancel: false,
        confirm: _vm.$t("OK"),
        persistent: "",
      },
      on: { confirm: _vm.clearError },
      model: {
        value: _vm.error,
        callback: function ($$v) {
          _vm.error = $$v
        },
        expression: "error",
      },
    },
    [
      _c(
        "template",
        { slot: "titleIcon" },
        [
          _c("v-icon", { attrs: { color: "error", size: "1.5em" } }, [
            _vm._v("error"),
          ]),
        ],
        1
      ),
      _vm._v("\n    " + _vm._s(_vm.error) + "\n"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }