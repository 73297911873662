import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import Page from "@components/Page.vue";
import Login from "@components/login/Login.vue";
import Join from '@components/join/Join.vue';
import Registration from '@components/registration/Registration.vue';
import Invitation from './components/invitation/Invitation.vue';
import LtiPage from './components/integration/lti/LtiPage.vue';
import Lti from './components/integration/lti/lti.vue';
import StoreAction from './interfaces/storeAction';
import StoreMutation from './interfaces/StoreMutation';
import store from '@/stores/store';
import * as JsonWebToken from 'jsonwebtoken';
import LtiStrings from './entities/strings/definitions/ltiStrings';
import LtiError from '@components/integration/lti/errors/ltiError.vue';
import Logout from '@components/logout/Logout.vue'
import PayVendible from '@components/vendibles/PayVendible.vue';
import VendiblePaymentConfirmed from '@components/vendibles/VendiblePaymentConfirmed.vue';
import { CreateUserParams } from './utils/userCreation/createUserParams';
import AddUserMethod from './interfaces/addUserMethod';
import { UserRole } from '@cyber-range/cyber-range-api-user-client';
import StoreGetter from './interfaces/storeGetter';
import Redirect from '@components/redirect/Redirect.vue'
import AccountRequest from '@components/signup/AccountRequest.vue'

async function allowLtiSso(to, from, next)
{
    let ltiToken = to.query['lti'];
    delete to.query['lti'];
    
    if(ltiToken)
    {
        if(await store.dispatch(StoreAction.ValidateToken, ltiToken))
        {
            store.commit(StoreMutation.SetLtiToken, ltiToken);
            
            let ltiIdentity = JsonWebToken.decode(ltiToken);

            if(ltiIdentity.provider?.startsWith('lti'))
            {
                store.commit(StoreMutation.SetLtiIdentity, ltiIdentity);
                store.commit(StoreMutation.ResetIdentityProvider, ltiIdentity.requestedProvider);

                return next();
            }
        }
        
        await store.dispatch(StoreAction.SetError, new Error(LtiStrings.en.LTI_INVALID_TOKEN));
    }
    
    next();
}

async function joinCourse(to: Route, from: Route, next)
{
    const isLoggedIn = await store.dispatch(StoreAction.Login, {
        token: to.query['s'],
        provider: 'sso'
    });

    if (!isLoggedIn)
    {
        return next({ name: 'login' });
    }

    try
    {
        await store.dispatch(StoreAction.CreateUser, new CreateUserParams(AddUserMethod.directJoin, {
            courseId: to.params['cid'],
            role: to.params['role'] as UserRole,
            userId: store.getters[StoreGetter.GetUserId]
        }));
    }
    catch (e)
    {
        return next({ name: 'login' });
    }
}

async function restorePaymentData(to: Route, from: Route, next)
{
    await store.dispatch(StoreAction.RestorePaymentRequiredData, to.params['vendibleid']);
    return next();
}

const routes = [
    {
        path: "/",
        component: Page,
        children:
        [
            {path: "/", component: Login, name: "login"},
            {path: "/registration", component: Registration, name: "registration"},
            {path: "/registration/:cid", component: Registration, name: "registration"},
            { path: "/invitations", component: Invitation},
            { path: "/invitations/:iid", component: Invitation, name: "invitation" },
            { path: "/join", component: Join},
            { path: "/join/:ctfid", component: Join, name: "join"},
            { path: '/logout', component: Logout, name: 'logout' },
            { path: '/vendibles/:vendibleid/pay', component: PayVendible, name: 'payVendible', beforeEnter: restorePaymentData },
            { path: '/vendibles/:vendibleid/confirmed', component: VendiblePaymentConfirmed, name: 'VendiblePaymentConfirmed', beforeEnter: restorePaymentData },
            { path: '/join-course/:cid/role/:role', component: Login, name: 'joinCourse', beforeEnter:joinCourse}
        ]
    },
    { path: "/signup", component: AccountRequest, name: "signup" },
    {
        path: "/lti",
        component: LtiPage,
        children: [
            { path: "/lti", component: Lti, name: "lti", beforeEnter: allowLtiSso },
            { path: "/lti/errors/:code", component: LtiError, name: "ltiError", props:true },
        ]
    },
    {
        path: "/redirect",
        component: Redirect,
        name: 'redirect',
    }
];

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
