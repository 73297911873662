var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    [
      _c("v-text-field", {
        attrs: {
          "prepend-icon": "person",
          name: "login",
          label: "Login",
          type: "text",
        },
        model: {
          value: _vm.username,
          callback: function ($$v) {
            _vm.username = $$v
          },
          expression: "username",
        },
      }),
      _vm._v(" "),
      _c("v-text-field", {
        attrs: {
          "prepend-icon": "lock",
          name: "password",
          label: "Password",
          id: "password",
          type: "password",
        },
        on: { keyup: _vm.keyUp },
        model: {
          value: _vm.password,
          callback: function ($$v) {
            _vm.password = $$v
          },
          expression: "password",
        },
      }),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                loading: _vm.isLoggingIn,
                "data-testing": "basic-auth-login-button",
              },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("LOGIN_TITLE")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }