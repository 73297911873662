import IPaymentRequiredData from '@/interfaces/iPaymentRequiredData';
import PaymentRequiredAction from '@/interfaces/paymentRequiredAction';
import StoreAction from '@/interfaces/storeAction';
import StoreGetter from '@/interfaces/storeGetter';
import StoreMutation from '@/interfaces/StoreMutation';
import router from '@/router';
import IStoreState from '@interfaces/iStoreState';
import NoPaymentSessionDataFoundError from '@/entities/errors/noPaymentSessionDataFoundError';
import { Token } from '@cyber-range/cyber-range-api-authentication-client';
import PaymentProcessorFactory from '../../utils/paymentProcessing/paymentProcessorFactory';

interface IStoredSessionData
{
    userToken: string;
    data: IPaymentRequiredData
}

export default {
    state:{
        requiredPaymentData: {},
        processingPaymentMessage: '',
    },
    getters: {
        [StoreGetter.GetVendiblePaymentRequiredError](state:IStoreState): (id: string) => IPaymentRequiredData
        {
            return (id: string) => state.requiredPaymentData[id];
        },
        [StoreGetter.GetProcessingPaymentMessage](state:IStoreState): string
        {
            return state.processingPaymentMessage;
        },
    },
    mutations: {
        [StoreMutation.SetPaymentRequiredData](state:IStoreState, data: IPaymentRequiredData): void
        {
            state.requiredPaymentData[data.vendibleId] = data;
        },
        [StoreMutation.SetProcessingPaymentMessage](state:IStoreState, message: string): void
        {
            state.processingPaymentMessage = message;
        },
    },
    actions: {
        [StoreAction.RestorePaymentRequiredData](context, vendibleId: string)
        {
            let data:  IStoredSessionData;
            try {
                data = JSON.parse(window.sessionStorage.getItem(vendibleId));
            } catch (error) {}

            let userToken: string;
            if (data)
            {
                context.commit(StoreMutation.SetPaymentRequiredData, data.data);
                userToken = data.userToken;
            }
            else
            {
                userToken = sessionStorage.getItem('token');
            }

            context.commit(StoreMutation.SetToken, new Token(userToken));
        },
        [StoreAction.PaymentRequired](context, data: IPaymentRequiredData)
        {
            context.commit(StoreMutation.SetPaymentRequiredData, data);

            const userToken = context.getters[StoreGetter.GetToken];
            window.sessionStorage.setItem(data.vendibleId, JSON.stringify({ data, userToken }));

            router.push(`/vendibles/${data.vendibleId}/pay`);
        },
        async [StoreAction.PurchaseVendible](context, vendibleId: string): Promise<void>
        {
            const vendibleApiClient = context.getters[StoreGetter.VendibleApiClient];
            const  { paymentUrl } = await vendibleApiClient.getPaymentUrl(vendibleId);

            if (window.self !== window.top)
            {
                const openedWindow = window.open(paymentUrl, undefined, 'popup=true');

                window.addEventListener('message', event => {
                    if (event.source !== openedWindow || event.data !== 'paymentProcessed')
                    {
                        return
                    }

                    router.push(`/vendibles/${vendibleId}/confirmed`);
                });
            }
            else
            {
                window.location.assign(paymentUrl);
            }
        },
        async [StoreAction.ProcessPaymentConfirmed](context, vendibleId: string)
        {
            if (window.opener)
            {
                window.opener.postMessage('paymentProcessed');
                window.close();
                return;
            }

            const paymentProcessor = await PaymentProcessorFactory.create(context, vendibleId);
            if (paymentProcessor.message)
            {
                context.commit(StoreMutation.SetProcessingPaymentMessage, paymentProcessor.message);
            }
            await paymentProcessor.process();
        }
    }
};
