import AddUserMethod from "../../interfaces/addUserMethod";
import { CreateUserArgs } from "./createUserArgs";

export interface ICreateUserParams<T extends AddUserMethod>
{
    method: T;
    args: CreateUserArgs<T>
}

export class CreateUserParams<T extends AddUserMethod>
{
    constructor(public method: T, public args: CreateUserArgs<T>) {}
}
