








































import StoreAction from "@/interfaces/storeAction";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from "vuex-class";
import StoreGetter from "@/interfaces/storeGetter";
import { IAuthenticationApiClient, ILtiIdentity } from "@cyber-range/cyber-range-api-authentication-client";
import { CourseApplication, CourseApplicationType, ICourse, IUserApiClient, UserRole } from "@cyber-range/cyber-range-api-user-client";
import Config from "@/config";
import Loading from "@/components/utils/Loading.vue";
import { ConflictApiClientError } from "@cyber-range/cyber-range-api-client";

@Component({components: {Loading}})
export default class LinkStep extends Vue 
{
    @Prop(Object) course:ICourse;
    @Prop(Boolean) active:boolean;
    @Getter(StoreGetter.GetLtiIdentity) ltiIdentity:ILtiIdentity;
    @Getter(StoreGetter.GetLtiToken) ltiToken:string;
    @Getter(StoreGetter.IsLoading) isLoading: boolean;
    @Getter(StoreGetter.UserApiClient) userApiClient:IUserApiClient;
    @Getter(StoreGetter.BackgroundAuthenticationApiClient) backgroundAuthenticationApiClient:IAuthenticationApiClient;
    @Action(StoreAction.GetSsoToken) getSsoToken: () => Promise<string>;
    @Action(StoreAction.LoadingBegin) loadingBegin: () => void;
    @Action(StoreAction.Login) login: (payload: { token, provider: 'vcr' }) => Promise<boolean>;
    @Action(StoreAction.SetError) setError;
    @Action(StoreAction.CustomApiErrorHandler) defaultErrorHandler: (e: Error) => void;

    isLinking:boolean = true;
    launchFailed:boolean = false;
    successfullyLinked:boolean = false;

    @Watch("active")
    async onActiveChanged()
    {
        if(!this.active) return;
        
        this.isLinking = true;

        try
        {
            let courseApplication = new CourseApplication({
                type: CourseApplicationType.Lti13,
                courseId: this.course.id,
                deploymentId: this.ltiIdentity.deploymentId,
                organizationId: this.ltiIdentity.organizationId,
                organizationApplicationId: this.ltiIdentity.organizationApplicationId,
                referenceId: this.ltiIdentity.contextId,
                referenceUrl: '',
            });

            await this.userApiClient.createCourseApplication(this.course.id, courseApplication);

            try
            {
                await this.backgroundAuthenticationApiClient.addCurrentIdentityAlternateIds([this.ltiIdentity.sub]);
            }
            catch(e)
            {
                if(e instanceof ConflictApiClientError)
                {
                    // The user already exists in the course. Do nothing.  
                    // We will promote/demote the user by calling User API's createUserByLtiToken() below.
                }
                else
                {
                    throw e;
                }
            }

            // Re-add the user to the course to ensure the role is correct. 
            // At this point, the LTI token should have contextId that User API can use to determine a courseId.
            if(this.ltiIdentity.roles.some(role => [UserRole.Instructor, UserRole.Student, UserRole.TA].includes(<any>role)))
            {
                try
                {
                    await this.userApiClient.createUserByLtiToken(this.ltiToken, {errorHandler: () => {}});
                }
                catch (e)
                {
                    if (e.statusCode === 402 && e.message.includes('payment'))
                    {
                        // Do nothing. Everything else succeeded. When redirection happens, the user will be sent to the payment page.
                    }
                    else
                    {
                        this.defaultErrorHandler(e);
                        throw e;
                    }
                }
            }

            this.successfullyLinked = true;
        }
        finally
        {
            this.isLinking = false;
        }

    }
    async cancel()
    {
        this.$emit('cancel');
    }
    async confirm()
    {
        if(await this.login({provider:'vcr', token: this.ltiToken}))
        {
            let ssoToken = await this.getSsoToken();

            // Disable all buttons while the user is being redirected to the destination.
            this.loadingBegin();

            window.location.href = new URL(`/courses/${this.course.id}?s=${ssoToken}`, Config.CYBER_RANGE_UI_BASE_URL).href;
        }
        else
        {
            this.setError(new Error(this.$t('LTI_LINK_STEP_LAUNCH_FAILED').toString()));
            this.launchFailed = true;
        }
    }
}
