enum StoreGetter
{
	IsLoading = "isLoading",
	//Authentication
	GetIdentityProvider = 'getIdentityProvider',
	GetUserId = 'getUserId',
	GetDisplayName = 'getDisplayName',
	GetToken = 'getToken',
	IsLogin = "isLogin",
	GetLtiIdentity = "getLtiIdentity",
	GetLtiToken = "getLtiToken",
	//Entitlement
	HasClaim = "hasClaim",
	GetClaim = "getClaim",
	GetClaims = "getClaims",
	Scopes = "scopes",
	//Course Applications
	CanCreateCourseApplication = "canCreateCourseApplication",
	//Authorization
	CanListCourses = "canListCourses",
	CanManageOrganization = "canManageOrganization",
	CanViewExerciseArea = "canViewExerciseArea",
    CanCreateBusinessUnitWithAccountRequest = 'canCreateBusinessUnitWithAccountRequest',
	// Error
	GetErrorCode = 'getErrorCode',
	GetErrorDetails = 'getErrorDetails',
	GetErrorMessage = "getErrorMessage",
	// Features
	GoogleLoginEnabled = 'googleLoginEnabled',
	AzureAdLoginEnabled = 'azureAdLoginEnabled',
	// API Clients
	AuthenticationApiClient = 'getAuthenticationApiClient',
	BackgroundAuthenticationApiClient = "getBackgroundAuthenticationApiClient",
	UserApiClient = 'getUserApiClient',
	BackgroundUserApiClient="BackgroundUserApiClient",
	InvitationApiClient = 'getInvitationApiClient',
	EntitlementApiClient = "getEntitlementApiClient",
	CompetitionApiClient = 'getCompetitionApiClient',
	PaymentApiClient = 'getPaymentApiClient',
	VendibleApiClient = 'getVendibleApiClient',
	BalanceApiClient = 'getBalanceApiClient',
	ContentApiClient = 'getContentApiClient',
	NewsletterApiClient = 'getNewsletterApiClient',
	OrganizationApiClient = 'getOrganizationApiClient',
	BackgroundOrganizationApiClient = 'getBackgroundOrganizationApiClient',
	BusinessUnitApiClient = 'getBusinessUnitApiClient',
	//CTF competition
	CanViewAllCompetitions = 'canViewAllCompetitions',
	CanAccessCompetition = 'canAccessCompetition',
	CanRegisterAsAdmin = 'canRegisterAsAdmin',
	CanRegisterAsPlayer = 'canRegisterAsPlayer',
	// Payment
	GetVendiblePaymentRequiredError="getVendiblePaymentRequiredError",
	GetProcessingPaymentMessage = "getProcessingPaymentMessage",
	// Signup
	BusinessUnitDisplayCategory = 'businessDisplayCategory',
	BusinessUnitDisplayRegion = 'businessDisplayRegion',
	BusinessUnitDisplayName = 'businessDisplayName',
}

export default StoreGetter;
