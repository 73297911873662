




import Vue from "vue";
import CredentialLoginButton from "./CredentialLoginButton.vue";
import Config from "@/config";
import Component from "vue-class-component";

@Component({
    components: { CredentialLoginButton }
})
export default class LoginButtons extends Vue
{
    get cognitoBaseUrl():string 
    {
        return Config.COGNITO_ANONYMOUS_POOL_BASE_URL;
    }

    get cognitoClientId():string 
    {
        return Config.COGNITO_ANONYMOUS_POOL_CLIENT_ID;
    }
}
