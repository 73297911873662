





import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from "vuex-class";

import Login from "@/components/login/Login.vue";
import StoreGetter from "@/interfaces/storeGetter";
import Loading from "@utils/Loading.vue";
import StoreAction from "@/interfaces/storeAction";

@Component({ components: { Loading, Login }})
export default class VendiblePaymentConfirmed extends Vue
{
    @Getter(StoreGetter.GetErrorMessage) error;
    @Action(StoreAction.SetError) setError: (e: Error) => void;
    loading: boolean = false;

    get vendibleId()
    {
        return this.$route.params.vendibleid;
    }

    get message(): string
    {
        return this.$store.getters[StoreGetter.GetProcessingPaymentMessage] || this.$t('VENDIBLE_PAYMENT_PROCESSING_MESSAGE');
    }

    async created()
    {
        this.loading = true
        try
        {
            await this.$store.dispatch(StoreAction.ProcessPaymentConfirmed, this.vendibleId);
        }
        catch(e)
        {
            if (e instanceof Error && e.name === 'NoPaymentSessionDataFoundError')
            {
                this.setError(new Error(this.$t('VENDIBLE_CONFIRMED_NO_DATA').toString()))
            }

            // Only set loading to false if there is an error so the loading message shows while redirecting
            this.loading = false
        }
    }
}
