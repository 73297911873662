




















import Vue from "vue";
import StoreAction from "@interfaces/storeAction";
import { setTimeout } from "timers";
import Loading from "@utils/Loading.vue";
import { GoneApiClientError, NotFoundApiClientError } from "@cyber-range/cyber-range-api-client";
import LoginButtons from "../login/LoginButtons.vue";
import Announcement from "@utils/Announcement.vue";
import { Action, Getter } from "vuex-class";
import Component from "vue-class-component";
import StoreGetter from "@interfaces/storeGetter";
import { ICompetitionApiClient, ICompetition, CompetitionFilter } from '@cyber-range/cyber-range-api-ctf-competition-client';
import Config from '@/config';
import { IUserApiClient } from '@cyber-range/cyber-range-api-user-client';
import { Base64 } from "js-base64";

@Component({
    components: { Loading, LoginButtons, Announcement }
})
export default class Join extends Vue 
{
    @Action(StoreAction.SetError) setError;
    @Getter(StoreGetter.CompetitionApiClient) competitionApiClient:ICompetitionApiClient;
    @Getter(StoreGetter.UserApiClient) userApiClient:IUserApiClient;
    @Action(StoreAction.GetSsoToken) getSsoToken: () => Promise<string>;
    @Action(StoreAction.TryLogin) tryLogin: () => Promise<boolean>;
    @Action(StoreAction.FetchClaims) fetchClaims: () => Promise<boolean>;
    @Getter(StoreGetter.CanAccessCompetition) canAccessCompetition: (competitionId:string, organizationId:string) => Promise<boolean>;
    @Getter(StoreGetter.CanRegisterAsAdmin) canRegisterAsAdmin: (courseId:string) => Promise<boolean>;
    @Getter(StoreGetter.CanRegisterAsPlayer) canRegisterAsPlayer: (courseId:string) => Promise<boolean>;
    @Getter(StoreGetter.GetErrorMessage) error;
    @Action(StoreAction.LoadingBegin) loadingBegin: () => void;
    @Action(StoreAction.LoadingEnd) loadingEnd: () => void;

    competition:ICompetition = <ICompetition> {};
    creatingUser: boolean = false;
    retryableError: boolean = true;
    loggingIn:boolean = false;

    get credentialCode() {
        return this.$route.query ? this.$route.query.code : undefined;
    }

    get competitionId(): string|undefined
    {
        if(this.$route.params.ctfid)
        {
            return this.$route.params.ctfid
        }

        let state = JSON.parse(Base64.decode(<string>this.$route.query?.state)) || {};

        return state.competitionId;
    }

    get providers()
    {
        const providers = ['google', 'azure']
        if (this.competition.settings?.allowDirectLogin)
        {
            providers.push('credential_user')
        }
        return providers;
    }

    async created() 
    {
        let page = await this.competitionApiClient.get(new CompetitionFilter(<any>{subdomain:this.competitionId}));
        
        if(page.items.length === 0)
        {
            this.setError({message: this.$root.$t('JOIN_COMPETITION_NOT_FOUND')});
            this.retryableError = false;
            return;
        }

        this.competition = page.items[0];
    }

    async onLoginSuccess() 
    {
        let redirectUrlFromState = this.$route.query?.state ? JSON.parse(Base64.decode(<string>this.$route.query?.state))?.redirectUrl : undefined;

        let ctfRedirectUrl = redirectUrlFromState || (<string> (this.$route.query.redirect_url 
                             ?  
                             this.$route.query.redirect_url :
                             (this.$route.query.state 
                                ? Config.CTF_UI_BASE_URL.replace('www', this.competitionId)
                                : Config.CTF_UI_BASE_URL)));

        await this.tryLogin();
        await this.fetchClaims();

        // Disable all buttons while the user is being redirected to the destination.
        this.loadingBegin();

        if(this.canAccessCompetition(this.competition.id, this.competition.organizationId))
        {
            this.loggingIn = true;
            await this.redirectToUrl(ctfRedirectUrl);
        }
        else if(this.credentialCode || this.canRegisterAsPlayer(this.competition.courseId) || this.canRegisterAsAdmin(this.competition.courseId))
        {
            await this.createUser(this.competition.subdomain);
            await this.redirectToUrl(ctfRedirectUrl);
        }
        else if(this.competition.settings?.endTime && this.competition.settings?.endTime <= new Date().toISOString())
        {
            this.setError({message: this.$t("JOIN_FAILED_COMPETITION_ENDED")});
        }
        else if(this.competition.settings?.allowPublicRegistration)
        {
            await this.createUser(this.competition.subdomain);
            await this.redirectToUrl(ctfRedirectUrl);
        }
        else
        {
            this.setError({message: this.$t("JOIN_FAILED_COMPETITION_PRIVATE")});

            this.loadingEnd();
        }
    }

    async createUser(subdomain:string)
    {
        try  
        {
            this.creatingUser = true;

            await this.userApiClient.createUserByCtfCompetitionSubdomain(subdomain);

            await new Promise((resolve, reject) =>setTimeout(resolve, 2000));

            await this.tryLogin();
        } 
        catch(e) 
        {
            //Do not set `creatingUser` back to true if all goes well. 
            //This is so that the loading kept showing until page redirection below.
            this.creatingUser = false; 
            throw e;
        }
    }

    async redirectToUrl(url:string): Promise<void>
    {
        try
        {
            let ssoToken = await this.getSsoToken();

            if(!Config.REDIRECT_URLS.split(',').some(u => u === url || new RegExp(u).test(url) === true))
            {
                return this.setError({ message: this.$t("INVALID_REDIRECT_URL", {url: this.$route.query.redirect_url}) });
            }

            if(!url.includes('?'))
            {
                url += '?';
            }    

            window.location.href = `${url}&s=${ssoToken}`;
        }
        catch(e)
        {
            this.loggingIn = false;
        }
    }
}
