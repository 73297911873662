import Config from '../../config';
import StoreGetter from '../../interfaces/storeGetter';
import { ICompetitionApiClient } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IInvitation, IInvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import IUserCreator from "./iUserCreator";
import UserCreator from "./userCreator";

export default class InvitationUserCreator extends UserCreator implements IUserCreator
{
    private invitationId: string;
    private _invitation: IInvitation
    async getInvitation()
    {
        if (!this._invitation)
        {
            this._invitation = await this.invitationApiClient.getOne(this.invitationId);
        }
        return this._invitation;
    }

    private get invitationApiClient(): IInvitationApiClient
    {
        return this.storeContext.getters[StoreGetter.InvitationApiClient];
    }

    private get competitionApiClient(): ICompetitionApiClient
    {
        return this.storeContext.getters[StoreGetter.CompetitionApiClient];
    }

    constructor(storeContext, invitation: string|IInvitation)
    {
        super(storeContext);

        if (typeof invitation === 'string')
        {
            this.invitationId = invitation
        }
        else
        {
            this.invitationId = invitation.id;
            this._invitation = invitation;
        }
    }

    create()
    {
        return this.userApiClient.createUserByInvitation(this.invitationId);
    }

    async afterCreate(): Promise<void>
    {
        const invitation = await this.getInvitation();

        if ( invitation.ctfCompetitionId )
        {
            let competition = await this.competitionApiClient.getOne(invitation.ctfCompetitionId);
            window.location.href = `https://${competition.subdomain}.${Config.CTF_UI_BASE_DOMAIN}?s=${await this.getSsoToken()}`
        }
        else
        {
            window.location.href = `${Config.CYBER_RANGE_UI_BASE_URL}?s=${await this.getSsoToken()}`;
        }
    }
}
