






















import { Component, Prop, VModel, Vue } from 'vue-property-decorator';

@Component
export default class AccessibleStepper extends Vue
{
    @VModel({ type: Number, default: 1 }) activeStep: number;
    @Prop({ type: Array, required: true }) steps: string[]
}
