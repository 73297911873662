import Vue from 'vue';
import Vuex from 'vuex';
import ApiClientStore from './modules/apiClientStore';
import ErrorStore from './modules/errorStore';
import FeatureStore from './modules/featureStore';
import AuthenticationStore from './modules/authenticationStore';
import AuthorizationStore from './modules/authorizationStore';
import EntitlementStore from './modules/entitlementStore';
import PaymentStore from './modules/paymentStore';
import UserStore from './modules/userStore';
import OrganizationStore from './modules/organizationStore';

Vue.use(Vuex);
const store = {
	modules: {
		AuthenticationStore,
		ApiClientStore,
		ErrorStore,
		FeatureStore,
		AuthorizationStore,
		EntitlementStore,
		PaymentStore,
		UserStore,
		OrganizationStore,
	}
};
export default new Vuex.Store(store);
