var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-card-text", { staticClass: "d-flex justify-center" }, [
        _c(
          "div",
          { staticStyle: { "max-width": "320px" } },
          [
            _c("login-buttons", {
              attrs: {
                providers: ["google", "azure"],
                mode: "lti",
                text: _vm.$t("LTI_LOGIN_STEP_TEXT"),
              },
              on: { confirm: _vm.onLoginSuccess },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }