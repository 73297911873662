export default {
    en: {
        REGISTRATION_LOADING_MESSAGE: "Please wait while your account is being prepared...",
        REGISTRATION_COURSE_REGISTRATION: "Register for a Course",
        INVITATION_CODE_LABEL: "Invitation Code",
        REGISTRATION_CONFIRM_MESSAGE:
            "You are registering for <b>{course}</b>. To finish this registration, please confirm how you would like to access the course by signing in using one of the following services:",
        REGISTRATION_INSTRUCTIONS:
            "Hello. To register for a course, please enter the invitation code given by your instructor below:",
        REGISTRATION_ALREADY_REGISTERED: "Already Registered?"
    }
};
