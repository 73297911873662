










import StoreAction from "@/interfaces/storeAction";
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LoginButtons from '@components/login/LoginButtons.vue';
import StoreGetter from "@/interfaces/storeGetter";

@Component({components: {LoginButtons}})
export default class LoginStep extends Vue {
    @Getter(StoreGetter.IsLoading) isLoading: boolean;
    @Getter(StoreGetter.GetLtiToken) ltiToken:string;
    @Action(StoreAction.TryLogin) tryLogin: () => Promise<boolean>;
    @Action(StoreAction.FetchClaims) fetchClaims: () => Promise<boolean>;

    async onLoginSuccess()
    {
        await this.tryLogin();
        await this.fetchClaims();
        this.$emit('confirm')
    }
}
