import { IClaim } from "@cyber-range/cyber-range-api-entitlement-client";
import StoreMutation from "../../interfaces/StoreMutation";
import StoreGetter from '../../interfaces/storeGetter';
import IStoreState from '../../interfaces/iStoreState';
import StoreAction from '../../interfaces/storeAction';

function getClaimId(type, value, scope)
{
    return `${scope || ''}::${type || ''}::${value || ''}`;
}

export default {
    state:{
        claims: {},
        scopes: []
    },
    getters: {
        [StoreGetter.HasClaim]: (state:IStoreState) => (type:string, valueOrValues:string|string[], scopeOrScopes:string|string[]):boolean => {

            let values = Array.isArray(valueOrValues) ? [...new Set(valueOrValues)] : [valueOrValues];
            let scopes = Array.isArray(scopeOrScopes) ? [...new Set(scopeOrScopes)] : [scopeOrScopes];

            for(let value of values)
            {
                for(let scope of scopes)
                {
                    let key = getClaimId(type, value, scope);
                    if(key in state.claims)
                    {
                        return true;
                    }
                }
            }

            return false;
        },
        [StoreGetter.GetClaim]: (state:IStoreState) => (type:string, valueOrValues:string|string[], scopeOrScopes:string|string[]):IClaim[] => {
            let result = [];
            let table = {};
            let values = valueOrValues === undefined ? Object.values(state.claims).map((claim:IClaim) => claim.value):
                                            (Array.isArray(valueOrValues)?
                                            [...new Set(valueOrValues)] : [valueOrValues]);
            let scopes = Array.isArray(scopeOrScopes) ? [...new Set(scopeOrScopes)] : [scopeOrScopes];

                for(let value of values)
                {
                    for(let scope of scopes)
                    {
                        let key = getClaimId(type, value, scope);
                        if(key in state.claims && !(key in table))
                        {
                            result.push(state.claims[key]);
                            table[key] = true;
                        }
                    }
                }

            return result;
        },
        [StoreGetter.GetClaims]: (state:IStoreState) => (type:string):IClaim[] => {
            return Object.values(state.claims).filter(claim => claim.type === type);
        },
        [StoreGetter.Scopes]: (state: IStoreState): string[] => {
            return state.scopes;
        }
    },
    mutations: {
        [StoreMutation.SetClaims](state:IStoreState, claims:IClaim[]) {
            state.claims = {};
            let scopes = new Set<string>();
            for(let claim of claims)
            {
                for(let scope of claim.scopes)
                {
                    scopes.add(scope);
                    state.claims[getClaimId(claim.type, claim.value, scope)] = claim;
                }
            }
            state.scopes = [...scopes];
        }
    },
    actions: {
        async [StoreAction.FetchClaims](context){
            let claims:IClaim[] = await context.getters.getEntitlementApiClient.listClaims();
            await context.commit(StoreMutation.SetClaims, claims);
        },
        async [StoreAction.ClearClaims](context){
            context.commit(StoreMutation.SetClaims, []);
        }
    }
};