







import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
@Component({})
export default class Loading extends Vue {
    @Prop() message;
}
