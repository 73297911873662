import Config from '@/config';
import StoreGetter from '@/interfaces/storeGetter';
import StoreAction from '@/interfaces/storeAction';
import { AuthenticationApiClient } from '@cyber-range/cyber-range-api-authentication-client';
import { ApiRequestConfig } from '@cyber-range/cyber-range-api-client';
import { TrackJS } from 'trackjs';

import store from '../store';
import IStoreState from '@interfaces/iStoreState';
import StoreMutation from '@interfaces/StoreMutation';
import { UserApiClient, IUserApiClient } from '@cyber-range/cyber-range-api-user-client';
import { InvitationApiClient, IInvitationApiClient } from '@cyber-range/cyber-range-api-invitation-client';
import { IEntitlementApiClient, EntitlementApiClient } from '@cyber-range/cyber-range-api-entitlement-client';
import { ICompetitionApiClient, CompetitionApiClient } from '@cyber-range/cyber-range-api-ctf-competition-client';
import { IPaymentApiClient, IVendibleApiClient, PaymentApiClient, VendibleApiClient } from '@cyber-range/cyber-range-api-payment-client'
import { IBalanceApiClient, BalanceApiClient } from '@cyber-range/cyber-range-api-balance-client'
import { IContentApiClient, ContentApiClient }from '@cyber-range/cyber-range-api-content-client';
import { IBusinessUnitApiClient, BusinessUnitApiClient } from '@cyber-range/cyber-range-api-business-unit-client';
import { IOrganizationApiClient, OrganizationApiClient } from '@cyber-range/cyber-range-api-organization-client';
import { NewsletterApiClient } from '@cyber-range/cyber-range-api-newsletter-client'

function customApiErrorHandler(e, contextOrStore = store) {
	TrackJS.track(e);
	contextOrStore.dispatch(StoreAction.SetError, e);
}
function apiCallingHandler() {
	store.dispatch(StoreAction.LoadingBegin);
}
function apiCalledHandler() {
	store.dispatch(StoreAction.LoadingEnd);
}

export default {
	state: {
		loading: 0
	},
	getters: {
		[StoreGetter.IsLoading](state: IStoreState): boolean {
			return state.loading > 0;
		},
		[StoreGetter.AuthenticationApiClient](state: IStoreState, getters, rootState): AuthenticationApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new AuthenticationApiClient(Config.AUTHENTICATION_API_BASE_URL, config);
		},
		[StoreGetter.BackgroundAuthenticationApiClient](state: IStoreState, getters, rootState): AuthenticationApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				undefined,
				undefined,
				undefined
			);
			return new AuthenticationApiClient(Config.AUTHENTICATION_API_BASE_URL, config);
		},
		[StoreGetter.EntitlementApiClient](state: IStoreState, getters, rootState): IEntitlementApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new EntitlementApiClient(Config.ENTITLEMENT_API_BASE_URL, config);
		},
		[StoreGetter.UserApiClient](state: IStoreState, getters, rootState): IUserApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new UserApiClient(Config.USER_API_BASE_URL, config);
		},
		[StoreGetter.BackgroundUserApiClient](state: IStoreState, getters, rootState): IUserApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
			);
			return new UserApiClient(Config.USER_API_BASE_URL, config);
		},
		[StoreGetter.InvitationApiClient](state: IStoreState, getters, rootState): IInvitationApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new InvitationApiClient(Config.INVITATION_API_BASE_URL, config);
		},
		[StoreGetter.CompetitionApiClient](state: IStoreState, getters, rootState): ICompetitionApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new CompetitionApiClient(Config.COMPETITION_API_BASE_URL, config);
		},
		[StoreGetter.PaymentApiClient](state: IStoreState, getters, rootState): IPaymentApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new PaymentApiClient(Config.PAYMENT_API_BASE_URL, config);
		},
		[StoreGetter.VendibleApiClient](state: IStoreState, getters, rootState): IVendibleApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new VendibleApiClient(Config.PAYMENT_API_BASE_URL, config);
		},
		[StoreGetter.BalanceApiClient](state: IStoreState, getters, rootState): IBalanceApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new BalanceApiClient(Config.BALANCE_API_BASE_URL, config);
		},
		[StoreGetter.ContentApiClient](state: IStoreState, getters, rootState): IContentApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new ContentApiClient(Config.CONTENT_API_BASE_URL, config);
		},
		[StoreGetter.BusinessUnitApiClient](state: IStoreState, getters, rootState): IBusinessUnitApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new BusinessUnitApiClient(Config.BUSINESS_UNIT_API_BASE_URL, config);
		},
		[StoreGetter.OrganizationApiClient](state: IStoreState, getters, rootState): IOrganizationApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new OrganizationApiClient(Config.ORGANIZATION_API_BASE_URL, config);
		},
		[StoreGetter.BackgroundOrganizationApiClient](state: IStoreState, getters, rootState): IOrganizationApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
			);
			return new OrganizationApiClient(Config.ORGANIZATION_API_BASE_URL, config);
		},
		[StoreGetter.NewsletterApiClient](state: IStoreState, getters, rootState): NewsletterApiClient {
			const config = new ApiRequestConfig(
				{ "authorization": `Bearer ${rootState.AuthenticationStore.token}`, 'User-Agent': undefined },
				undefined,
				customApiErrorHandler,
				apiCallingHandler,
				apiCalledHandler
			);
			return new NewsletterApiClient(Config.NEWSLETTER_API_BASE_URL, config);
		},
	},
	mutations: {
		[StoreMutation.IncrementLoading](state: IStoreState) {
			state.loading = state.loading + 1;
		},
		[StoreMutation.DecrementLoading](state: IStoreState) {
			state.loading = state.loading - 1;
		}
	},
	actions: {
		[StoreAction.LoadingBegin](context) {
			context.commit(StoreMutation.IncrementLoading);
		},
		[StoreAction.LoadingEnd](context) {
			context.commit(StoreMutation.DecrementLoading);
		},
		[StoreAction.CustomApiErrorHandler] (context, e) {
			customApiErrorHandler(e, context);
		}
	}
};
