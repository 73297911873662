var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "msal-login-button",
      style: _vm.buttonStyle,
      attrs: {
        text: "",
        outlined: "",
        block: "",
        loading: _vm.loading || _vm.authInProgress,
        disabled: _vm.loading || _vm.authInProgress,
        "data-testing": "microsoft-login-button",
      },
      on: { click: _vm.msalLogin },
    },
    [
      _c(
        "v-layout",
        {
          staticClass: "px-2",
          attrs: { row: "", wrap: "", "align-center": "" },
        },
        [
          _c("v-img", {
            attrs: {
              "max-height": "20",
              "max-width": "20",
              src: _vm.azureLogo,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-xs-left text-none pl-3 flex-grow-1",
              staticStyle: { "font-family": '"Google Sans",arial,sans-serif' },
            },
            [_vm._v(_vm._s(_vm.$t("LOGIN_AZURE_AD_TEXT")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }