import AddUserMethod from '@/interfaces/addUserMethod';
import IPaymentRequiredData from '@/interfaces/iPaymentRequiredData';
import PaymentRequiredAction from '@/interfaces/paymentRequiredAction';
import StoreAction from '@/interfaces/storeAction';
import IUserCreator from '@/utils/userCreation/iUserCreator';
import { ICreateUserParams } from '@/utils/userCreation/createUserParams';
import { UserCreatorFactory } from '@/utils/userCreation/userCreatorFactory';
import { ApiClientError, ConflictApiClientError } from '@cyber-range/cyber-range-api-client';

export default {
    actions: {
        async [StoreAction.CreateUser](context, createUserData: ICreateUserParams<AddUserMethod>)
        {
            const userCreator: IUserCreator = UserCreatorFactory.create(context, createUserData.method, createUserData.args);
            try
            {
                await userCreator.create();
            }
            catch (e)
            {
                if (e instanceof ApiClientError && e.statusCode === 402)
                {
                    let data: object|undefined
                    try
                    {
                        data = JSON.parse(e.details);
                        // if data does not contain a vendible id, treat it as any other api error (handled in the catch block)
                        if (!data['vendibleId']) throw new Error();
                    }
                    catch 
                    {
                        context.dispatch(StoreAction.CustomApiErrorHandler, e);
                        throw e;
                    }

                    context.dispatch(StoreAction.PaymentRequired, {
                        ...data,
                        source: {
                            action: PaymentRequiredAction.UserCreation,
                            method: createUserData.method,
                            args: createUserData.args,
                        } as IPaymentRequiredData['source'],
                    });
                    return;
                }
                else if (e instanceof ConflictApiClientError && e.message === 'The user has already accepted an invitation')
                {
                    // Ignore error when the invitation has already been accepted so the user is logged in and redirected to the course as if the create succeeded
                }
                else
                {
                    context.dispatch(StoreAction.CustomApiErrorHandler, e);
                    throw e;
                }
            }

            await new Promise((resolve, reject) => setTimeout(resolve, 2000));

            await context.dispatch(StoreAction.TryLogin);

            await userCreator.afterCreate();
        },
    }
};
