
































import Vue from 'vue'
import Component from 'vue-class-component';
import Config from '@/config';
import { Action, Getter } from "vuex-class";
import { resolve } from 'url';

@Component({ components: { }})
export default class StudentRegistration extends Vue {
    get isUSCR(): boolean {
        return Config.BASE_DOMAIN === 'USCR';
    }
    
    confirm() {
        window.location.href = resolve(Config.LOGIN_UI_BASE_URL, `/registration`);
    }
}
