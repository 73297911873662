













import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import StoreGetter from "@interfaces/storeGetter";
import azureLogo from '../../../assets/azure.svg';
import StoreAction from '@/interfaces/storeAction';
import Config from '@/config';
import { Base64 } from "js-base64";

@Component({})
export default class CredentialLoginButton extends Vue {
    @Getter(StoreGetter.GetErrorMessage) error;
    @Prop() credentialCode;
    @Prop() competitionId;
    @Prop() mode;
    @Prop() provider;
    @Prop() cognitoClientId;
    @Prop() cognitoBaseUrl;
    @Prop() label;
    @Prop() icon;
    @Getter(StoreGetter.IsLoading) loading: boolean;
    @Action(StoreAction.SetError) setError;
    @Action(StoreAction.Login) login;
    @Action(StoreAction.ResetError) resetError;
    authInProgress = false;
    
    buttonStyle(): object {
        return {
            height: '48px',
            'background-color': 'white'
        }
    }

    async credentialLogin() 
    {
        let redirectUrl = new URL(`/${this.mode}`, Config.LOGIN_UI_BASE_URL);

        let data = Base64.encode(JSON.stringify({
            competitionId: this.competitionId,
            provider: this.provider,
            redirectUrl: this.$route.query.redirect_url
        }), true);

        window.location.href = new URL(`/login?response_type=code&redirect_uri=${redirectUrl.href}&client_id=${this.cognitoClientId}&state=${data}`, this.cognitoBaseUrl).href;
    }

    get state(): {competitionId:string, provider:string, redirectUrl:string}
    {
        return JSON.parse(Base64.decode(<string>this.$route.query?.state) || {});
    }

    async created() 
    {
        try
        {
            this.authInProgress = true;

            if (this.credentialCode && this.provider === this.state.provider)
            {
                const redirectUrl = new URL(window.location.pathname, window.location.origin).href;

                if (await this.login({ provider: this.provider, token: btoa(JSON.stringify({code: this.credentialCode, redirectUrl }))})) 
                {
                    this.$emit("confirm");
                    this.$store.dispatch(StoreAction.ResetError);
                } 
                else 
                {
                    if (!this.error)
                    {
                        this.setError({ message: this.$t("LOGIN_UNABLE_TO_LOGIN_TEXT") });
                    }
                }
            }
        }
        catch(e)
        {
            this.setError(e);
            TrackJS.track(e);
        }
        finally
        {
            this.authInProgress = false;
        }
    }
}
