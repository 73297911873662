import { AuthenticationApiClient, IToken, LtiIdentity, ILtiIdentity } from '@cyber-range/cyber-range-api-authentication-client';
import StoreAction from "../../interfaces/storeAction";
import Config from '../../config';
import IStoreState from '../../interfaces/iStoreState';
import StoreGetter from '../../interfaces/storeGetter';
import StoreMutation from '../../interfaces/StoreMutation';
import * as JsonWebToken from 'jsonwebtoken';

export default {
	state: {
		identityProvider: "",
		userId: '',
		token: '',
		displayName: '',
		tokenExpirationTimeInMs: 0,
		resources: [],
		ltiIdentity: new LtiIdentity(),
		ltiToken: ''
	},
	getters: {
		[StoreGetter.GetToken](state: IStoreState): string {
			return state.token;
		},
		[StoreGetter.GetIdentityProvider](state: IStoreState): string {
			return state.identityProvider;
		},
		[StoreGetter.GetUserId](state: IStoreState): string {
			return state.userId;
		},
		[StoreGetter.GetDisplayName](state: IStoreState): string {
			return state.displayName;
		},
		[StoreGetter.IsLogin](state: IStoreState): boolean {
			return state.token ? new Date().getTime() < state.tokenExpirationTimeInMs : false;
		},
		[StoreGetter.GetLtiIdentity](state: IStoreState): ILtiIdentity {
			return state.ltiIdentity
		},
		[StoreGetter.GetLtiToken](state: IStoreState): string {
			return state.ltiToken
		}
	},
	mutations: {
		[StoreMutation.ResetIdentityProvider](state: IStoreState, value: string) {
			state.identityProvider = value;
			if(value)
			{
				localStorage.setItem('identityProvider', value);
			}
			else
			{
				localStorage.removeItem('identityProvider');
			}
		},
		[StoreMutation.SetToken](state: IStoreState, token: IToken) {
			const tokenString = token ? token.value : undefined;

			state.token = tokenString;

			if (tokenString) {
				const jwt = JsonWebToken.decode(tokenString);
				state.tokenExpirationTimeInMs = (jwt['exp'] - 30) * 1000; // 30 seconds

				state.identityProvider = jwt['provider'];
				state.userId = jwt['sub'];
				state.displayName = jwt['displayName'];
				localStorage.setItem('identityProvider', state.identityProvider);
				sessionStorage.setItem('token', state.token);
			} else {
				state.tokenExpirationTimeInMs = 0;
			}
		},
		[StoreMutation.SetLtiIdentity](state: IStoreState, value: ILtiIdentity) {
			state.ltiIdentity = value;
		},
		[StoreMutation.SetLtiToken](state: IStoreState, token: string) {
			state.ltiToken = token;
		}
	},
	actions: {
		async [StoreAction.Login](context, request: { token: string, provider: string }): Promise<boolean> {
			const client = new AuthenticationApiClient(Config.AUTHENTICATION_API_BASE_URL);

			try {
				let token = await client.authenticate(request.provider, request.token);
				context.commit(StoreMutation.SetToken, token);
			} catch {
				return false;
			}

			return true;
		},
		async [StoreAction.TryLogin](context): Promise<boolean> {
			const token = context.getters.getToken;
			if (token) {
				return context.dispatch(StoreAction.Login, { token, provider: 'vcr' });
			} else {
				return false;
			}
		},
		async [StoreAction.ValidateToken](context, token: string): Promise<boolean> {
			const client = new AuthenticationApiClient(Config.AUTHENTICATION_API_BASE_URL);

			try {
				return await client.validate(token);
			} catch {
				return false;
			}
		},
		async [StoreAction.GetSsoToken](context): Promise<string> {
			return await context.getters.getAuthenticationApiClient.sso();
		}
	}
};
