export default {
    en: {
        INVITATION_LOADING_MESSAGE: "Please wait while your account is being prepared...",
        INVITATION_CONFIRMATION: "Invite Confirmation",
        INVITATION_ACCEPTED_ERROR_MESSAGE: "The invitation has been accepted. Please login below to access your account.",
        INVITATION_NOT_FOUND_ERROR_MESSAGE: "The invitation could not be found. Please verify your invitation link and try again.",
        INVITATION_REJECTED_MESSAGE:'The invitation has been rejected. Please contact your organization for more details.',
        INVITATION_PENDING_APPROVAL_MESSAGE:'The invitation is pending for approval. You will be informed once the invitation has been approved.',
        INVITATION_WELCOME_TEXT: "Hi {name}! To finish signing up, just confirm how you would like to access the portal by signing in using one of the following services:",
        COMPETITION_NOT_FOUND_ERROR_MESSAGE: "The competition could not be found. Please verify your invitation link and try again."
    }
};
