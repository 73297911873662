import AddUserMethod from '@/interfaces/addUserMethod';
import Config from '../../config';
import { CreateUserArgs } from './createUserArgs';
import IUserCreator from "./iUserCreator";
import UserCreator from "./userCreator";

export default class LtiUserCreator extends UserCreator implements IUserCreator
{
    private ltiToken: string;
    private courseId: string;

    constructor(storeContext, data: CreateUserArgs<AddUserMethod.lti>)
    {
        super(storeContext);
        this.ltiToken = data.ltiToken;
        this.courseId = data.courseId;
    }

    create()
    {
        return this.userApiClient.createUserByLtiToken(this.ltiToken);
    }

    async afterCreate()
    {
        window.location.href = new URL(`/courses/${this.courseId}?s=${await this.getSsoToken()}`, Config.CYBER_RANGE_UI_BASE_URL).href;
    }
}
